import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from "@angular/core";
import { DecorateUntilDestroy, takeUntilDestroyed } from "@app/shared/rxjs/operator/take-until-destroyed";
import { distinctUntilChanged, map } from "rxjs/operators";
import { StoreService } from "../../service/video-store/store.service";

enum FullScreenIconUrlEnum {
  enter = 'assets/icons/plyr-enter-fullscreen.svg',
  exit = 'assets/icons/plyr-exit-fullscreen.svg',
}

@DecorateUntilDestroy()
@Component({
  selector: 'rd-video-full-screen-control',
  templateUrl: './full-screen-control.html'
})

export class RdVideoFullScreenControlComponent {
  iconUlr$ = this.storeService.selectPlayerIsFullScreen$()
    .pipe(
      map(value => value
        ? FullScreenIconUrlEnum.exit
        : FullScreenIconUrlEnum.enter
      )
    )

  constructor(private storeService: StoreService) { }

  onControlClick(): void {
    const isFullScreen = this.storeService.getPlayerIsFullScreen()
    this.storeService.updatePlayerIsFullScreen(!isFullScreen)
  }
}
