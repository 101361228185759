import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material.module';
import { SharedDirectivesModule } from '@app/shared/directives';
import { ComponentLoaderModule, DialogWrapperModule } from '@app/shared/ui';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { VideoPlayerEffects, videoPlayerReducer } from './store';
import { VideoPlayerVideoshotContainerComponent, VideoPlayerVideoshotPopupComponent } from './video-player-videoshot-popup';
import { VideoPlayerComponent } from './video-player.component';
import {NgxTranslateModule} from '@app/translate/translate.module';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { DurationInputComponent } from './video-player-videoshot-popup/duration-input/duration-input.component';
import { NgxMaskModule } from 'ngx-mask';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RdVideoModule } from '@app/shared/video/rd-video.module';

/**
 * Module with videoplayer
 * helpers/
 * plyr-controls - classes for working with player controls (custom or native)
 * plyr-globals - variables which uses in some places in module
 * hls-frag-parse-helper - class with methods for Hls fragment parsing
 * hls-helper - class with methods for working with Hls
 * rdea-hls - class which extends basic Hls class
 * rdea-plyr - class which extends basic Plyr class
 *
 * models/ - directory with models for video player
 * services/ - directory with video player services
 * store/ - classes for working with module state
 *
 * video-player-videoshot-popup/ - popup and content components for creating video player videoshot
 * video-player.constants - constants which uses in all module
 */

@NgModule({
    imports: [
        RdVideoModule,
        FormsModule,
        CommonModule,
        MaterialModule,
        ProgressSpinnerModule,
        DialogWrapperModule,
        ReactiveFormsModule,
        ComponentLoaderModule,
        NgxMatTimepickerModule,
        NgxMatNativeDateModule,
        SharedDirectivesModule,
        NgxMatDatetimePickerModule,
        EffectsModule.forFeature([
            VideoPlayerEffects
        ]),
        StoreModule.forFeature('video-player', videoPlayerReducer),
        NgxTranslateModule, 
        NgxMatMomentModule, 
        NgxMaskModule.forRoot()
    ],
    declarations: [
        VideoPlayerComponent,
        VideoPlayerVideoshotPopupComponent,
        VideoPlayerVideoshotContainerComponent,
        DurationInputComponent

    ],
    exports: [
        VideoPlayerComponent
    ],
    providers: [DatePipe],
})
export class VideoPlayerModule { }
