<div 
  class="time-manage-container"
  [class.left]="type === 'prev'"
  [class.right]="type === 'next'"
>
  <p-button
    [icon]="getIcon()" 
    [iconPos]="getIconPosition()"
    [label]="getLabel()"
    styleClass="p-button-info p-button-text"
    (onClick)="onClick()">
  </p-button>
</div>