import { Component, ElementRef, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";
import { RdVideoService } from "../../service/video.service";
import { DecorateUntilDestroy, takeUntilDestroyed } from "@app/shared/rxjs/operator/take-until-destroyed";
import { catchError, filter, map, mergeMap, skip, take, tap } from "rxjs/operators";
import { PlayerStateEnum } from "../../models/state.model";
import { MAX_FATALS_COUNT, TZ_OFFSET_IN_MILLISECONDS, TZ_OFFSET_IN_MINUTES, VideoPlayerFragmentShortDate, VideoPlayerMode } from "@app/shared/components/video-player";
import { forkJoin, Observable, of } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { Option } from "../../models/select.model";
import { StoreService } from "../../service/video-store/store.service";
import { SignalService } from "../../signal-serivice/signal.service";
import { HttpApiService } from "../../message-service/http-api.service";
import { MediaService } from "../../media.service/media.service";
import { PeerConnectionService } from "../../peer-connection/peer-connection.service";
import { HlsConnectionService } from "../../hls-service/hls-connection.service";
import { DatePipe } from "@angular/common";
import Hls, { ErrorDetails, ErrorTypes, Fragment } from "hls.js";
import { RdvaApiService, RdvaPlaylistItem } from "@app/shared/entities/integrations";
import { Camera, CameraApiService } from "@app/shared/entities/rd";
import { Store } from "../../store/store";
import { SnackbarService } from "@app/shared/components";
import { parseError } from "@app/shared/helpers";
import { HlsManifestItems, TypeReceiver } from "../../models/hls.model";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { MessageService } from "../../message-service/message.service";
import { HlsEventsService } from "../../hls-service/hls-events.service";

export interface ScreenItem {
  index: number;
  cameraId: number;
  camera?: Camera;
}
@DecorateUntilDestroy()
@Component({
  selector: 'rd-video-manager',
  templateUrl: './video-manager.html',
  styleUrls: ['./video-manager.scss'],
  providers: [
    PeerConnectionService,
    HlsConnectionService,
    HlsEventsService,
    MediaService,
    RdVideoService,
    SignalService,
    MessageService,
    StoreService,
    Store
  ],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('hoverAnimation', [
      state(
        'hidden',
        style({
          transform: 'translateY(100%)',
          opacity: 0,
        })
      ),
      state(
        'visible',
        style({
          transform: 'translateY(0)',
          opacity: 1,
        })
      ),
      transition('hidden <=> visible', animate('300ms ease-in-out')),
    ]),
  ],
})
export class RdVideoManager {
  @Input() streamId: number;
  @Input() camera: ScreenItem;
  @Input() isShowOpenDoor: boolean;
  @Input() isShowControl: boolean;

  @Output() openDoorEvent = new EventEmitter();
  @Output() openVideoShotPopupEvent = new EventEmitter<HlsManifestItems>();
  @Output() requestFullscreenEvent = new EventEmitter<boolean>();

  isHoveredControl$ = this.storeService.selectIsHoveredControl$();
  isHoveredTimeline$ = this.storeService.selectIsHoveredTimeline$();

  timeStepManage = 10;

  public readonly VideoPlayerMode = VideoPlayerMode;

  public get rdvaURI(): string {
    return this.camera?.camera?.rdva?.uri;
  }

  mode$ = this.storeService.selectMode$();

  isShowDownloadVideoShotBtn = this.storeService.selectPlaylistHlsManifestItems$()
    .pipe(
      map(value => !!value && value.length !== 2)
    );

  eventsOptions$ = this.storeService.selectTimelineData$()
    .pipe(
      map(data => {
        const eventOptions = data.eventTimestamps
          .map(event => this.rdVideoService.createOption(event, this.formatDate(event.startTimestamp, 'HH:mm:ss')));

        this.storeService.updateEventOptions(eventOptions);
        return eventOptions;
      })
    )

  currentPlaylistOption$ = this.storeService.selectCurrentPlaylist$()
    .pipe(
      map(currentPlaylist => this.rdVideoService.createOption(currentPlaylist, this.formatDate(currentPlaylist.date, 'dd MMMM')))
    )

  dateArchiveOptions$ = this.storeService.selectPlayListItems$()
    .pipe(
      map(playlistItems => playlistItems
        .map(playlist => this.rdVideoService.createOption(playlist, this.formatDate(playlist.date, 'dd MMMM')))
      )
    )

  constructor(
    private rdVideoService: RdVideoService,
    private rdvaApiService: RdvaApiService,
    private storeService: StoreService,
    private translate: TranslateService,
    private datePipe: DatePipe,
    private snackbar: SnackbarService,
    private cameraApiService: CameraApiService
  ) { }

  ngOnInit(): void {
    this.storeService.updateMode(VideoPlayerMode.LIVE_WEBRTC)
    this.subscribeChangeModePlayer();
    this.subscribeRequestFullscreen();
    this.handleHlsError();

    this.storeService.onHlsPlaylistLoadError$()
      .pipe(takeUntilDestroyed(this))
      .subscribe(err =>{
        this.snackbar.showMessage(
          this.translate.instant('shared.video_player.message.get_playlists.failed', {
            text: parseError(err)
          })
        );
      })
  }

  onMouseEnter() {
    const mode = this.storeService.getMode()

    if (mode === VideoPlayerMode.ARCHIVE) {
      this.storeService.updateIsHoveredControl(true)
      return;
    }
    this.storeService.updateIsHoveredControl(true)
    this.storeService.updateIsHoveredTimeline(true)
  }

  onMouseLeave() {
    const mode = this.storeService.getMode()

    if (mode === VideoPlayerMode.ARCHIVE) {
      this.storeService.updateIsHoveredControl(false)
      return;
    }
    this.storeService.updateIsHoveredControl(false)
    this.storeService.updateIsHoveredTimeline(false)
  }

  getModeButtonName(): string {
    switch (this.storeService.getMode()) {
      case VideoPlayerMode.LIVE_WEBRTC:
        return 'LIVE old';
      case VideoPlayerMode.LIVE_HLS:
        return this.translate.instant('shared.video_player.plyr.template.button.archive');
      case VideoPlayerMode.ARCHIVE:
        return 'LIVE new';
    }
  }

  getModeButtonIcon(): string {
    switch (this.storeService.getMode()) {
      case VideoPlayerMode.LIVE_WEBRTC:
        return 'pi pi-circle-fill pi-icon--red';
      case VideoPlayerMode.LIVE_HLS:
        return 'pi pi-save';
      case VideoPlayerMode.ARCHIVE:
        return 'pi pi-circle-fill pi-icon--red';
    }
  }

  changeMode() {
    switch (this.storeService.getMode()) {
      case VideoPlayerMode.LIVE_WEBRTC:
        this.rdVideoService.destroyWebRTCStream(this.streamId);
        this.storeService.updateMode(VideoPlayerMode.LIVE_HLS)
        break;
      case VideoPlayerMode.LIVE_HLS:
        this.rdVideoService.destroyHlsStream(this.streamId);
        this.storeService.updateMode(VideoPlayerMode.ARCHIVE)
        break;
      case VideoPlayerMode.ARCHIVE:
        this.rdVideoService.destroyHlsStream(this.streamId);
        this.storeService.updateMode(VideoPlayerMode.LIVE_WEBRTC)
        break;
    }
  }

  openDoor(): void {
    this.openDoorEvent.emit(null);
  }

  openVideoShotPopup(): void {
    const hlsManifestItems = this.storeService.getPlaylistHlsManifestItems()
    this.openVideoShotPopupEvent.emit(hlsManifestItems);
  }

  handleSelectionPlaylist(option: Option<RdvaPlaylistItem>) {
    this.storeService.updateCurrentPlaylist(option.value);
  }

  private handleSelectionEvents(option: Option<VideoPlayerFragmentShortDate>) {
    this.storeService.updateCurrentEvent(option);
    this.storeService.fireNewPlayerCurrentTime(option.value.startPlayerTime);
  }

  private subscribeRequestFullscreen(): void {
    this.storeService.selectPlayerIsFullScreen$()
      .pipe(takeUntilDestroyed(this))
      .subscribe(value => {
        this.requestFullscreenEvent.emit(value)
      })
  }

  private subscribeChangeModePlayer(): void {
    this.mode$
      .pipe(
        takeUntilDestroyed(this)
      )
      .subscribe((mode) => {
        switch (mode) {
          case VideoPlayerMode.LIVE_WEBRTC:
            this.initLiveWebRTC()
            break;
          case VideoPlayerMode.LIVE_HLS:
            this.initLiveHls()
            break;
          case VideoPlayerMode.ARCHIVE:
            this.initArchive()
            break;
          default:
            break;
        }
      })
  }

  private initLiveHls(): void {
    this.initHls(TypeReceiver.LIVE);
  }

  private initLiveWebRTC(): void {
    this.rdVideoService.addReceiverWebRTC({
      id: this.streamId
    });
  }

  private initArchive(): void {
    this.initHls(TypeReceiver.ARCHIVE);
  }

  private initHls(type: TypeReceiver) {
    this.rdVideoService.addReceiverHls({
      id: this.streamId,
      hostUri: this.rdvaURI,
      type
    });
  }

  private formatDate(timestamp: number, format: string = 'dd MMMM'): string {
    return this.datePipe.transform(timestamp - TZ_OFFSET_IN_MILLISECONDS, format);
  }

  private getPlaylistEdges(playlistItems: RdvaPlaylistItem[]): Observable<HlsManifestItems> {
    const [firstPlaylist, lastPlaylist] = [playlistItems[0].playlist, playlistItems[playlistItems.length - 1].playlist];

    const firstPlaylistObservable = this.rdvaApiService.getPlaylist(firstPlaylist)
    const lastPlaylistObservable = this.rdvaApiService.getPlaylist(lastPlaylist)

    return forkJoin([firstPlaylistObservable, lastPlaylistObservable])
  }

  private handleHlsError(): void {
    this.storeService.onHlsConnectionErrorType$()
      .pipe(
        skip(MAX_FATALS_COUNT),
        filter(error => error.fatal),
        takeUntilDestroyed(this)
      )
      .subscribe(error => {
        this.showFatalErrorMessage(error.type, error.details);
      })
  }

  private showFatalErrorMessage(errorType: ErrorTypes, details: ErrorDetails) {
    let reason: string;

    switch (errorType) {
      case Hls.ErrorTypes.NETWORK_ERROR:
        reason = details === ErrorDetails.LEVEL_EMPTY_ERROR
          ? this.translate.instant('shared.video_player.message.error.reason.empty')
          : this.translate.instant('shared.video_player.message.error.reason.error')
          ;
        break;
      case Hls.ErrorTypes.MEDIA_ERROR:
        reason = this.translate.instant('shared.video_player.message.error.reason.media');
        break;
      default:
        reason = this.translate.instant('shared.video_player.message.error.reason.unknown');
    }

    this.snackbar.showMessage(
      this.translate.instant('shared.video_player.message.error.reason.unknown', {
        text: reason
      })
    );
  }
}
