import { HttpErrorResponse } from '@angular/common/http';
import { Camera, ServiceCameraUpdateRequest, ServiceCreateRequest } from '@app/shared/entities/rd';
import { Action } from '@ngrx/store';
import { SoftwareIntercomsPageModel } from '../models';
import { PageData } from '@app/shared/models/paged-response';
import { SortData } from '@app/shared/models/sort-order.type';

export enum SoftwareIntercomsPageActionTypes {
    GetSoftwareIntercomsPage = '[SoftwareIntercoms] GetSoftwareIntercomsPage',
    GetSoftwareIntercomsPageSuccess = '[SoftwareIntercoms] GetSoftwareIntercomsPage Success',
    GetSoftwareIntercomsPageFailure = '[SoftwareIntercoms] GetSoftwareIntercomsPage Failure',

    CreateSoftwareIntercom = '[SoftwareIntercoms] CreateSoftwareIntercom',
    CreateSoftwareIntercomSuccess = '[SoftwareIntercoms] CreateSoftwareIntercom Success',
    CreateSoftwareIntercomFailure = '[SoftwareIntercoms] CreateSoftwareIntercom Failure',

    DeleteSoftwareIntercom = '[SoftwareIntercoms] DeleteSoftwareIntercom',
    DeleteSoftwareIntercomSuccess = '[SoftwareIntercoms] DeleteSoftwareIntercom Success',
    DeleteSoftwareIntercomFailure = '[SoftwareIntercoms] DeleteSoftwareIntercom Failure',

    ConnectSoftwareIntercomPanelToCamera = '[SoftwareIntercoms] ConnectSoftwareIntercomPanelToCamera',
    ConnectSoftwareIntercomPanelToCameraSuccess = '[SoftwareIntercoms] ConnectSoftwareIntercomPanelToCamera Success',
    ConnectSoftwareIntercomPanelToCameraFailure = '[SoftwareIntercoms] ConnectSoftwareIntercomPanelToCamera Failure',

    DisconnectSoftwareIntercomPanelFromCamera = '[SoftwareIntercoms] DisconnectSoftwareIntercomPanelFromCamera',
    DisconnectSoftwareIntercomPanelFromCameraSuccess = '[SoftwareIntercoms] DisconnectSoftwareIntercomPanelFromCamera Success',
    DisconnectSoftwareIntercomPanelFromCameraFailure = '[SoftwareIntercoms] DisconnectSoftwareIntercomPanelFromCamera Failure'
}

export class GetSoftwareIntercomsPage implements Action {
    readonly type = SoftwareIntercomsPageActionTypes.GetSoftwareIntercomsPage;

    constructor(public pageNumber: number = 0, public size: number = 10, public name?: string, public sort?: SortData) { }
}

export class GetSoftwareIntercomsPageSuccess implements Action {
    readonly type = SoftwareIntercomsPageActionTypes.GetSoftwareIntercomsPageSuccess;

    constructor(
        public softwareIntercoms: SoftwareIntercomsPageModel[], 
        public pageData: PageData
    ) {}
}

export class GetSoftwareIntercomsPageFailure implements Action {
    readonly type = SoftwareIntercomsPageActionTypes.GetSoftwareIntercomsPageFailure;

    constructor(public error: HttpErrorResponse) { }
}

export class CreateSoftwareIntercom implements Action {
    readonly type = SoftwareIntercomsPageActionTypes.CreateSoftwareIntercom;

    constructor(public request: ServiceCreateRequest, public options: { filterValue: string, pageIndex: number }) { }
}

export class CreateSoftwareIntercomSuccess implements Action {
    readonly type = SoftwareIntercomsPageActionTypes.CreateSoftwareIntercomSuccess;

    constructor() { }
}

export class CreateSoftwareIntercomFailure implements Action {
    readonly type = SoftwareIntercomsPageActionTypes.CreateSoftwareIntercomFailure;

    constructor() { }
}

export class DeleteSoftwareIntercom implements Action {
    readonly type = SoftwareIntercomsPageActionTypes.DeleteSoftwareIntercom;

    constructor(public serviceId: number, public options: { pageIndex: number, pageSize: number, filterValue: string }) { }
}

export class DeleteSoftwareIntercomSuccess implements Action {
    readonly type = SoftwareIntercomsPageActionTypes.DeleteSoftwareIntercomSuccess;

    constructor() { }
}

export class DeleteSoftwareIntercomFailure implements Action {
    readonly type = SoftwareIntercomsPageActionTypes.DeleteSoftwareIntercomFailure;

    constructor() { }
}

export class ConnectSoftwareIntercomPanelToCamera implements Action {
    readonly type = SoftwareIntercomsPageActionTypes.ConnectSoftwareIntercomPanelToCamera;

    constructor(public cameraId: number, public request: ServiceCameraUpdateRequest) { }
}

export class ConnectSoftwareIntercomPanelToCameraSuccess implements Action {
    readonly type = SoftwareIntercomsPageActionTypes.ConnectSoftwareIntercomPanelToCameraSuccess;

    constructor(public camera: Camera) { }
}

export class ConnectSoftwareIntercomPanelToCameraFailure implements Action {
    readonly type = SoftwareIntercomsPageActionTypes.ConnectSoftwareIntercomPanelToCameraFailure;

    constructor() { }
}

export class DisconnectSoftwareIntercomPanelFromCamera implements Action {
    readonly type = SoftwareIntercomsPageActionTypes.DisconnectSoftwareIntercomPanelFromCamera;

    constructor(public cameraId: number) { }
}

export class DisconnectSoftwareIntercomPanelFromCameraSuccess implements Action {
    readonly type = SoftwareIntercomsPageActionTypes.DisconnectSoftwareIntercomPanelFromCameraSuccess;

    constructor() { }
}

export class DisconnectSoftwareIntercomPanelFromCameraFailure implements Action {
    readonly type = SoftwareIntercomsPageActionTypes.DisconnectSoftwareIntercomPanelFromCameraFailure;

    constructor() { }
}

export type SoftwareIntercomsActions =
    | GetSoftwareIntercomsPage
    | GetSoftwareIntercomsPageSuccess
    | GetSoftwareIntercomsPageFailure

    | CreateSoftwareIntercom
    | CreateSoftwareIntercomSuccess
    | CreateSoftwareIntercomFailure

    | DeleteSoftwareIntercom
    | DeleteSoftwareIntercomSuccess
    | DeleteSoftwareIntercomFailure

    | DisconnectSoftwareIntercomPanelFromCamera
    | DisconnectSoftwareIntercomPanelFromCameraSuccess
    | DisconnectSoftwareIntercomPanelFromCameraFailure;
