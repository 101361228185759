import { NgModule } from "@angular/core";
import { RdVideoControlComponent } from "./components/control/control";
import { RdVideoComponent } from "./components/video/video.component";
import { CommonModule } from '@angular/common';
import { NgxTranslateModule } from '@app/translate/translate.module';
import { RdVideoPlayControlComponent } from "./components/play-control/play-control";
import { RdVideoTimelineComponent } from "./components/timeline/timeline";
import { FormsModule } from "@angular/forms";
import { RdVideoTimelineSliderComponent } from "./components/timeline-slider/timeline-slider";
import { RdVideoTimelineTooltipComponent } from "./components/timeline-tooltip/timeline-tooltip";
import { RdVideoSelectControlComponent } from "./components/select-control/select-control";
import { PaginatorModule } from "primeng/paginator";
import { RdVideoTimelineCanvasComponent } from "./components/timeline-canvas/timeline-canvas";
import { RdVideoEventControlComponent } from "./components/event-control/event-control";
import { RdVideoManager } from "./components/video-manager/video-manager";
import { ButtonModule } from 'primeng/button';
import { RdVideoVolumeControlComponent } from "./components/volume-control/volume-control";
import { SliderModule } from 'primeng/slider';
import { MaterialModule } from '@app/material.module';
import { RdVideoFullScreenControlComponent } from "./components/full-screen-control/full-screen-control";
import { RdVideoPipControlComponent } from "./components/pip-control/pip-control";
import { RdVideoTimeManageControlComponent } from "./components/time-manage-control/time-manage-control";
import { SharedDirectivesModule } from "../directives";

@NgModule({
  imports: [
    MaterialModule,
    SliderModule,
    ButtonModule,
    CommonModule,
    NgxTranslateModule,
    FormsModule,
    PaginatorModule,
    SharedDirectivesModule
  ],
  declarations: [
    RdVideoTimeManageControlComponent,
    RdVideoPipControlComponent,
    RdVideoFullScreenControlComponent,
    RdVideoVolumeControlComponent,
    RdVideoEventControlComponent,
    RdVideoTimelineCanvasComponent,
    RdVideoTimelineTooltipComponent,
    RdVideoTimelineSliderComponent,
    RdVideoSelectControlComponent,
    RdVideoTimelineComponent,
    RdVideoPlayControlComponent,
    RdVideoComponent,
    RdVideoControlComponent,
    RdVideoManager
  ],
  exports: [
    RdVideoTimeManageControlComponent,
    RdVideoPipControlComponent,
    RdVideoFullScreenControlComponent,
    RdVideoVolumeControlComponent,
    RdVideoEventControlComponent,
    RdVideoSelectControlComponent,
    RdVideoTimelineComponent,
    RdVideoPlayControlComponent,
    RdVideoComponent,
    RdVideoControlComponent,
    RdVideoManager
  ]
})
export class RdVideoModule { }
