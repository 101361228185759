import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { PermissionsTreeKey } from '@app/security';
import { Account } from '@app/shared/entities/rd/account/models';
import { GetDelegationResponse } from '@app/shared/entities/rd/delegations/models';
import {
  Abonent,
  Address,
  SignUpRequestV2,
  SignUpResponse
} from '@app/shared/models';
import { State } from '@app/store/reducers';
import { AccountServiceData } from '@app/views/abonents/models';
import {
  BlockAbonentPageAccount,
  DeleteAbonentPageAccount,
  DeleteAbonentPageDelegation,
  GetAbonentPageAccountServices,
  UnblockAbonentPageAccount,
  UpdateAbonentPageAccount,
  UpdateAbonentPageSignUp
} from '@app/views/abonents/store/actions';
import { Store } from '@ngrx/store';
import { BehaviorSubject, forkJoin, Subject } from 'rxjs';
import {
  AbonentService,
  IAbonentConnection,
  IAbonentDelegation,
  IConnection,
  ServiceApiService
} from '@app/shared/entities/rd';
import { debounceTime, takeUntil } from 'rxjs/operators';
import {
  IMenuItems,
  IPrimeTableConfig
} from '@app/shared/components/prime-base-table/prime-base-table.component';
import { FormControl } from '@angular/forms';
import { RequestsService } from '@app/shared/services';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AbonentSignUpsComponent } from '@app/views/abonents/components';
import {openCloseH, openCloseHew} from '@app/shared/animations/animations';
import {OverlayPanel} from 'primeng/overlaypanel';
import {ViewportRuler} from '@angular/cdk/overlay';
import { NavigationService } from '@app/shared/services/navigation.service';

export interface IConvertedSignUpResponse {
  signUpId: number;
  abonentId: number;
  phone: string | number;
  houseId: number;
  isVirtual: boolean;
  isFreeAddress: boolean;
  isOwner: boolean;
  isDelegated: boolean;
  entranceId: number;
  address: Address;
  fullAddress: string;
  flatNumber: number;
  createdAt: number | Date | string;
  updatedAt: number;
  companyName: string;
  companyId: number;
  addressStr: string;
  adCampaign: string;
  adSource: string;
  status: string;
}

@Component({
  selector: 'app-abonent-owner',
  templateUrl: './abonent-owner.component.html',
  styleUrls: ['./abonent-owner.component.scss'],
  animations: [openCloseH, openCloseHew],
})
export class AbonentOwnerComponent implements OnDestroy, OnInit {
  readonly permissionsTreeKey: typeof PermissionsTreeKey = PermissionsTreeKey;
  @ViewChild('abonentSignUps', { static: false })
  abonentSignUps: AbonentSignUpsComponent;

  @Input() activeLink: string;
  @Input() abonentId: number;
  @Input() owner!: {
    delegations: GetDelegationResponse[];
    signUps: SignUpResponse[];
    accounts: Account[];
  };
  @Input() accountServices: AccountServiceData[];

  @Output() abonentChange: EventEmitter<void> = new EventEmitter<void>();

  public delegationSearch: string = null;
  public ownSearch: string = null;
  public cardView: boolean;
  public showOwnCards = true;
  public showDelegationCards = false;
  public abonentConnection: IConnection[] = [];
  public abonentDelegation: IConnection[] = [];
  public connectionsTableConfig: IPrimeTableConfig =
    this.getConnectionsTableConfig();
  public delegationsTableConfig: IPrimeTableConfig =
    this.getDelegationsTableConfig();
  public ordersTableConfig: IPrimeTableConfig = this.getOrdersTableConfig();
  public menuItems: IMenuItems<IConvertedSignUpResponse>[];
  public selectOptions: { label: string; value: string }[] = [
    { label: this.translate.instant('abonent.sign_ups.field.request_on_connection'), value: 'CONNECTION' },
    { label: this.translate.instant('abonent.sign_ups.field.request_on_disconnection'), value: 'DISCONNECTION' }
  ];
  public selectControl = new FormControl(this.selectOptions[0], []);
  public showSkeleton$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );

  protected delegationSubject$: Subject<string> = new Subject<string>();
  protected ownSubject$: Subject<string> = new Subject<string>();
  protected sharedSubject$: Subject<string> = new Subject<string>();
  private onDestroy$: Subject<void> = new Subject();

  constructor(
    private requestsService: RequestsService,
    private service: ServiceApiService,
    private router: Router,
    private store: Store<State>,
    private abonentService: AbonentService,
    private changeDetectorRef: ChangeDetectorRef,
    private translate: TranslateService,
    public signUpsService: RequestsService,
    private viewportRuler: ViewportRuler,
    private navigationService: NavigationService
  ) {}

  ngOnInit(): void {
    this.cardView = this.viewportRuler.getViewportSize().width < 600;
    forkJoin({
      delegation: this.service.toAbonentDelegation(this.abonentId),
      connection: this.service.abonentConnection(this.abonentId)
    })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((response) => {
        this.abonentDelegation = this.getMergedDelegation(response.delegation);
        this.abonentConnection = this.getMergedConnections(response.connection);
        this.changeDetectorRef.markForCheck();
        this.showSkeleton$.next(false);
      });
    this.setUpSubjectListening();
    this.menuItems = this.initMenuItem();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public onSelectAccount(accountId: number): void {
    this.store.dispatch(new GetAbonentPageAccountServices(accountId));
  }

  public onEditAccount(accountId: number, accountNumber: string): void {
    this.store.dispatch(new UpdateAbonentPageAccount(accountId, accountNumber));
  }

  public onDeleteAccount(accountId: number): void {
    this.store.dispatch(new DeleteAbonentPageAccount(accountId));
  }

  public onBlockAccount(accountId: number): void {
    this.store.dispatch(new BlockAbonentPageAccount(accountId));
  }

  public onUnblockAccount(accountId: number): void {
    this.store.dispatch(new UnblockAbonentPageAccount(accountId));
  }

  public onDeleteDelegation(delegationId: number): void {
    this.store.dispatch(new DeleteAbonentPageDelegation(delegationId));
  }

  public onUpdateSignUp(signUpId: number, request: SignUpRequestV2): void {
    this.store.dispatch(new UpdateAbonentPageSignUp(signUpId, request));
  }

  public onSelectChange(value: 'CONNECTION' | 'DISCONNECTION'): void {
    this.requestsService
      .getSignUpsV2List(0, 100, {
        abonentId: this.abonentId,
        signUpType: value
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((response) => {
        this.owner.signUps = response.content;
      });
  }

  public onNavigate(event: {
    data: IConnection | IAbonentConnection | IAbonentDelegation;
    field?: string;
  }): void {
    switch (event.field) {
      case 'serviceType':
        this.navigationService.navigateToService((event.data as IConnection).service);
        break;
      case 'fromAbonentPhone':
        this.fromAbonentProcess(event.data as IAbonentDelegation);
        break;
      default:
        throw Error('Unknown field type');
    }
  }

  public onDelegationCardShow(): void {
    this.showDelegationCards = !this.showDelegationCards;
    this.showOwnCards = false;
  }

  public onOwnCardShow(): void {
    this.showOwnCards = !this.showOwnCards;
    this.showDelegationCards = false;
  }

  public onMenuItemSelect(item: IMenuItems<IConvertedSignUpResponse>, op: OverlayPanel, data: IConvertedSignUpResponse): void {
    item.action(data);
    op.toggle(false);
  }

  public onSharedInputClick(event: MouseEvent): void {
    event.stopPropagation();
    if (!this.showDelegationCards) {
      this.showDelegationCards = true;
      this.showOwnCards = false;
    }
  }

  public onOwnInputClick(event: MouseEvent): void {
    event.stopPropagation();
    if (!this.showOwnCards) {
      this.showOwnCards = true;
      this.showDelegationCards = false;
    }
  }

  private getConnectionsTableConfig(): IPrimeTableConfig {
    return {
      key: 'id',
      showActionColumn: false,
      rolesAccessMode: false,
      dropdownPaginationMod: false,
      header: [
        { name: '', field: 'isBlocked', className: 'w-20', sort: false },
        { name: this.translate.instant('abonent.sign_ups.field.services'), field: 'serviceType', className: '', sort: false },
        { name: this.translate.instant('abonent.sign_ups.field.address'), field: 'fullAddress', className: '', sort: false },
        {
          name: this.translate.instant('abonent.sign_ups.field.account'),
          field: 'accountNumber',
          className: '',
          sort: false
        }
      ],
      body: [
        { className: 'w-20', field: 'isBlocked', isServiceStatus: true },
        { className: '', field: 'serviceType', isBaseLink: true },
        { className: '', field: 'fullAddress' },
        { className: '', field: 'accountNumber' }
      ]
    };
  }

  private getDelegationsTableConfig(): IPrimeTableConfig {
    return {
      key: 'id',
      showActionColumn: false,
      rolesAccessMode: false,
      dropdownPaginationMod: false,
      header: [
        { name: '', field: 'isBlocked', className: 'w-20', sort: false },
        { name: this.translate.instant('abonent.sign_ups.field.services'), field: 'serviceType', className: '', sort: false },
        { name: this.translate.instant('abonent.sign_ups.field.address'), field: 'fullAddress', className: '', sort: false },
        {
          name: this.translate.instant('abonent.sign_ups.field.account'),
          field: 'accountNumber',
          className: '',
          sort: false
        },
        {
          name: this.translate.instant('abonent.sign_ups.field.abonent'),
          field: 'fromAbonentPhone',
          className: '',
          sort: false
        }
      ],
      body: [
        { className: 'w-20', field: 'isBlocked', isServiceStatus: true },
        { className: '', field: 'serviceType', isBaseLink: true },
        { className: '', field: 'fullAddress' },
        { className: '', field: 'accountNumber' },
        { className: '', field: 'fromAbonentPhone', isBaseLink: true }
      ]
    };
  }

  private getOrdersTableConfig(): IPrimeTableConfig {
    return {
      key: 'id',
      showActionColumn: false,
      showCustomActionColumn: true,
      rolesAccessMode: false,
      header: [
        { name: this.translate.instant('abonent.sign_ups.field.address'), field: 'addressStr', className: '', sort: false },
        { name: this.translate.instant('abonent.sign_ups.field.flat'), field: 'flat', className: '', sort: false },
        { name: this.translate.instant('abonent.sign_ups.field.tube'), field: 'isVirtual', className: '', sort: false },
        {
          name: this.translate.instant('abonent.sign_ups.field.progress'),
          field: 'adaptiveStatus',
          className: '',
          sort: false
        },
        {
          name: this.translate.instant('abonent.sign_ups.field.created_date'),
          field: 'createdAt',
          className: '',
          sort: false
        }
      ],
      body: [
        { className: '', field: 'addressStr', isBaseLink: false },
        { className: '', field: 'flat' },
        { className: '', field: 'isVirtual', isTube: true },
        { className: '', field: 'adaptiveStatus' },
        { className: '', field: 'createdAt' }
      ]
    };
  }

  private getMergedConnections(
    connections: IAbonentConnection[]
  ): IConnection[] {
    const tempArr = [];
    connections.forEach((services) => {
      tempArr.push(
        ...services.connections.map((s) => {
          return {
            ...s,
            address: services?.flat?.address,
          };
        })
      );
    });
    return tempArr;
  }

  private getMergedDelegation(
    delegations: IAbonentDelegation[]
  ): IConnection[] {
    const tempArr = [];
    delegations.forEach((services: IAbonentDelegation) => {
      tempArr.push({
        ...services.connection,
        fromAbonent: services.fromAbonent,
        toAbonent: services.toAbonent
      });
    });
    return tempArr;
  }

  private setUpSubjectListening(): void {
    this.delegationSubject$
      .pipe(debounceTime(300), takeUntil(this.onDestroy$))
      .subscribe((value) => {
        this.delegationSearch = value;
      });
    this.ownSubject$
      .pipe(debounceTime(300), takeUntil(this.onDestroy$))
      .subscribe((value) => {
        this.ownSearch = value;
      });
    this.sharedSubject$
      .pipe(debounceTime(300), takeUntil(this.onDestroy$))
      .subscribe((value) => {
        this.delegationSearch = value;
      });
  }

  private fromAbonentProcess(connection: IAbonentDelegation): void {
    if (this.showOwnCards === false && this.cardView) {
      this.abonentConnection = [];
      this.showOwnCards = true;
      this.showDelegationCards = false;
    }
    this.abonentId = connection.fromAbonent.id;
    this.router
      .navigate([`/abonents/subscriber/${this.abonentId}`])
      .then(() => {
        this.abonentChange.emit();
        this.ngOnInit();
      });
  }

  private initMenuItem(): IMenuItems<IConvertedSignUpResponse>[] {
    return [
      {
        name: (value: IConvertedSignUpResponse) => {
          if (
            !this.signUpsService.activeSignUpWithCompany(
              value as unknown as SignUpResponse
            )
          ) {
            return null;
          }
          return value.isFreeAddress
            ? this.translate.instant('sign_ups.page.button.connect')
            : this.translate.instant('sign_ups.page.button.delegate');
        },
        action: (value: IConvertedSignUpResponse) => {
          value.isFreeAddress
            ? this.abonentSignUps?.onConnect(value as unknown as SignUpResponse)
            : this.abonentSignUps?.onDelegate(value as unknown as SignUpResponse);
        },
        style: 'cursor: pointer; color: #0da7e2; font-weight: bold;'
      },
      {
        name: (value: IConvertedSignUpResponse) => {
          if (
            !this.signUpsService.unprocessedCompanySignUp(
              value as unknown as SignUpResponse
            )
          ) {
            return null;
          }
          return this.translate.instant('sign_ups.page.button.processed');
        },
        action: (value: IConvertedSignUpResponse) => {
          this.abonentSignUps?.onProcessed(value as unknown as SignUpResponse);
        },
        style:
          'cursor: pointer; color: #0da7e2; margin-top: 5px; font-weight: bold;'
      },
      {
        name: () => {
          return this.translate.instant('sign_ups.page.button.info');
        },
        action: (value: IConvertedSignUpResponse) => {
          this.abonentSignUps?.onInfo(value as unknown as SignUpResponse);
        },
        style:
          'cursor: pointer; color: #0da7e2; margin-top: 5px; font-weight: bold;'
      },
      {
        name: (value: IConvertedSignUpResponse) => {
          if (
            !this.signUpsService.activeSignUpWithCompany(
              value as unknown as SignUpResponse
            )
          ) {
            return null;
          }
          return this.translate.instant('sign_ups.page.button.reject');
        },
        action: (value: IConvertedSignUpResponse) => {
          this.abonentSignUps?.onReject(value as unknown as SignUpResponse);
        },
        style:
          'cursor: pointer; color: #f44336; margin-top: 5px; font-weight: bold;'
      }
    ];
  }
}
