import { Injectable } from '@angular/core';
import { ServiceCreateRequest } from '@app/shared/entities/rd';
import { State } from '@app/store/reducers';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { VideoSurveillancePageModel } from './../models/video-surveillance-page.model';
import { CreateVideoSurveillance, DeleteVideoSurveillance, GetVideoSurveillanceList } from './video-surveillance-list.actions';
import { videoSurveillanceListSelector } from './video-surveillance-list.selectors';
import { PageData } from '@app/shared/models';
import { SortData } from '@app/shared/models/sort-order.type';

@Injectable()
export class VideoSurveillanceListFacade {
    videoSurveillanceList$: Observable<VideoSurveillancePageModel[]> = this.store.select(videoSurveillanceListSelector.getVideoSurveillanceList);
    pageData$: Observable<PageData> = this.store.select(videoSurveillanceListSelector.getPageData);
    loading$: Observable<boolean> = this.store.select(videoSurveillanceListSelector.getLoading);

    constructor(private store: Store<State>) { }

    getPage(page: number = 0, size: number = 10, name?: string, sort?: SortData) {
        this.store.dispatch(new GetVideoSurveillanceList(page, size, name, sort));
    }

    create(request: ServiceCreateRequest, options: { filterValue: string, pageIndex: number }) {
        this.store.dispatch(new CreateVideoSurveillance(request, options));
    }

    delete(serviceId: number, options: { pageIndex: number, pageSize: number, filterValue: string }) {
        this.store.dispatch(new DeleteVideoSurveillance(serviceId, options));
    }
}
