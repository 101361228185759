import { HlsManifestItems } from "../models/hls.model";
import { VideoShotData } from "../models/video.models";

export class HlsHelper {
  static getDateFromFragmentRelUrl(relurl: string, cameraId: number): Date {
    const timestamp = relurl?.split(`/dlarchive/${cameraId}/`)[1]?.replace('.ts', '');

    if (!timestamp) {
      return null;
    }

    const splittedDateAndTime: string[] = timestamp.split('T');
    const splittedDate: string[] = splittedDateAndTime[0].split('-');
    const dateObj = {
      year: Number.parseInt(splittedDate[0], 10),
      month: Number.parseInt(splittedDate[1], 10),
      day: Number.parseInt(splittedDate[2], 10),
      hour: Number.parseInt(splittedDate[3], 10),
      minute: Number.parseInt(splittedDate[4], 10),
      second: Number.parseInt(splittedDate[5], 10)
    };
    const timezoneOffsetInHours: number = new Date().getTimezoneOffset() / 60;

    return new Date(
      dateObj.year,
      dateObj.month - 1,
      dateObj.day,
      dateObj.hour - timezoneOffsetInHours,
      dateObj.minute,
      dateObj.second - Number.parseFloat(splittedDateAndTime[1])
    );
  }

  static getPlaylistRelUrl(isFirstPlaylist: boolean, data: string): string {
    const startIndex = isFirstPlaylist ? data.indexOf('/dlarchive') : data.lastIndexOf('/dlarchive');
    const endIndex = (isFirstPlaylist ? data.indexOf('.ts') : data.lastIndexOf('.ts')) + 3;
    return data.substring(startIndex, endIndex);
  }

  static shiftMinDateForward(minDate: Date, maxDate: Date): Date {
    const defaultShiftTime: number = (60 * 5) * 1000;

    if (minDate.getTime() + defaultShiftTime > maxDate.getTime()) {
      return minDate;
    }

    return new Date(minDate.getTime() + defaultShiftTime);
  }

  static getVideoShotRangeData(items: HlsManifestItems, id: number): VideoShotData {
    const firstPlaylistUrl = HlsHelper.getPlaylistRelUrl(true, items[0]);
    const lastPlaylistUrl = HlsHelper.getPlaylistRelUrl(false, items[1]);

    let minDate = HlsHelper.getDateFromFragmentRelUrl(firstPlaylistUrl, id);
    const maxDate = HlsHelper.getDateFromFragmentRelUrl(lastPlaylistUrl, id);

    minDate = HlsHelper.shiftMinDateForward(minDate, maxDate);

    return {
      minDate,
      maxDate
    }
  }
}
