import { Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef } from "@angular/core";
import { DecorateUntilDestroy, takeUntilDestroyed } from "@app/shared/rxjs/operator/take-until-destroyed";
import { distinctUntilChanged, map, skip, startWith, tap } from "rxjs/operators";
import { StoreService } from "../../service/video-store/store.service";

interface SliderEvent {
  value: number
}

enum VolumeIconUrlEnum {
  mute = 'assets/icons/plyr-muted.svg',
  unmute = 'assets/icons/plyr-volume.svg',
}

@DecorateUntilDestroy()
@Component({
  selector: 'rd-video-volume-control',
  templateUrl: './volume-control.html',
  styleUrls: ['./volume-control.scss']
})

export class RdVideoVolumeControlComponent implements OnInit {
  value = this.getVolume();

  iconUlr$ = this.storeService.selectPlayerVolumeIsMuted$()
    .pipe(
      map(value => value
        ? VolumeIconUrlEnum.mute
        : VolumeIconUrlEnum.unmute
      )
    );

  constructor(private storeService: StoreService) { }

  ngOnInit(): void {
    this.storeService.onVolumeChange$()
      .pipe(
        map(value => value === 0),
        distinctUntilChanged(),
        takeUntilDestroyed(this)
      )
      .subscribe((value) => {
        this.storeService.updatePlayerVolumeIsMuted(value)
      });
  }

  onControlClick(): void {
    const currentIsMuted = this.storeService.getPlayerVolumeIsMuted();
    const isMuted = !currentIsMuted
    this.storeService.updatePlayerVolumeIsMuted(isMuted);
    if (isMuted) {
      this.value = 0;
    } else {
      this.value = this.storeService.getPlayerVolume();
    }
  }

  handleChange(event: SliderEvent): void {
    this.storeService.fireVolumeChange(event.value)
    this.storeService.updatePlayerVolume(event.value);
  }

  private getVolume(): number {
    const currentIsMuted = this.storeService.getPlayerVolumeIsMuted();
    if (currentIsMuted) {
      return 0;
    }

    return this.storeService.getPlayerVolume();
  }
}
