import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@app/shared/api';
import { Camera } from '@app/shared/entities/rd/camera/models';
import { PagedResponse } from '@app/shared/models';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { CameraLease } from './camera-lease.model';
import { IServiceInfoResponse } from '@app/shared/entities/rd/camera/models/serviceInfoResponse';
import { HumanErrorTranslationService } from '@app/shared/services/human-error-translation.service';

export interface ICamerasRdaConfig {
  uid: string;
  releId: number;
}


export interface PersonalCameraConfig {
  user: string;
  password: string;
  uri: string;
  isPrivate: boolean;
  locationId?: number;
  personal: boolean;
  address?: any;
  audio: boolean;
}

@Injectable({ providedIn: 'root' })
export class CameraApiService extends ApiService {
  readonly rdvaHlsPort = environment.RDVAHlsPort;
  readonly rdvaPort = environment.RDVAPort;

  constructor(httpClient: HttpClient, humanErrorTranslationService: HumanErrorTranslationService) {
    super(httpClient, humanErrorTranslationService, environment.camerasApiUrl, ['v1', 'v2']);
  }

  getCameraServices(cameraId: number): Observable<IServiceInfoResponse[]> {
    return this.get(`/cameras/${cameraId}/services`, 1);
  }

  getCamerasPage(page: number, size: number, sort: string, search?: string): Observable<PagedResponse<Camera>> {
    const params = { page: page, size: size } as any;

    if (search) {
      if (!isNaN(Number.parseInt(search, 10)) || search.indexOf('rtsp://') !== -1) {
        params.search = search;
      } else {
        params.address = search;
      }
    }

    if (sort) {
      params.sort = sort;
    }

    return this.get('/cameras', 2, { params });
  }

  getCamerasList(entranceId?: number, isPrivate?: boolean, rdaUid?: number, rdvaUid?: number, serviceId?: number): Observable<Camera[]> {
    const params: any = {};
    if (entranceId) {
      params['entrance_id'] = entranceId;
    }
    if (isPrivate) {
      params['private'] = isPrivate;
    }
    if (rdaUid) {
      params['rda_uid'] = rdaUid;
    }
    if (rdvaUid) {
      params['rdva_uid'] = rdvaUid;
    }
    if (serviceId) {
      params['service_id'] = serviceId;
    }
    if (serviceId) {
      params['order_by'] = serviceId;
    }

    return this.get('/cameras', 1, {params});
  }

  addCamera(camera: Camera, connectToAbonents?: boolean): Observable<Camera> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const httpParams = new HttpParams().set('connect_to_abonents', connectToAbonents !== undefined ? String(connectToAbonents) : 'true');
    const request = {
      address: camera.address,
      configuration: camera.configuration,
      isPrivate: camera.private,
      locationId: camera.location?.id ?? null,
      password: camera.password,
      rdaUid: camera.rdaUid,
      rdvaId: null,
      uri: camera.uri,
      user: camera.user,
      onvifPort: camera.onvifPort,
      macAddress: camera.macAddress,
      modelId: camera.model?.id,
      connectedToRda: camera.connectedToRda,
      audio: camera.audio
    };

    return this.post('/cameras', 1, request, { headers: headers, params: httpParams });
  }

  public addCamera2(payload: Partial<PersonalCameraConfig>): Observable<Camera> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.post('/cameras', 1, payload, { headers: headers });
  }

  updateCamera(camera: Camera): Observable<void> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const request = {
      configuration: camera.configuration,
      isPrivate: camera.private,
      locationId: camera.location?.id ?? null,
      password: camera.password,
      rdaUid: camera.rdaUid,
      rdvaId: null,
      uri: camera.uri,
      user: camera.user,
      onvifPort: camera.onvifPort,
      audio: camera.audio,
      deviceId: camera.deviceId
    };

    return this.patch(`/cameras/${camera.id}`, 1, request, { headers });
  }

  public updateCamera2(cameraId: number, payload: Partial<PersonalCameraConfig>): Observable<Camera> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.patch(`/cameras/${cameraId}`, 1, payload, { headers });
  }

  deleteCamera(id: number): Observable<void> {
    return this.delete<void>(`/cameras/${id}`, 1);
  }

  getEntranceCameras(entranceId: number): Observable<Camera[]> {
    const httpParams = new HttpParams().set('entrance_id', entranceId.toString());
    return this.get<Camera[]>('/cameras', 1, { params: httpParams });
  }

  getLeases(rdaUid: string): Observable<CameraLease[]> {
    const httpParams = new HttpParams().set('rda_uid', rdaUid.toString());
    return this.get<CameraLease[]>('/leases', 1, { params: httpParams });
  }

  getRtspLink(rdvaUri: string, cameraId: number): string {
    return `rtsp://s.${rdvaUri}/${cameraId}`;
  }

  getHlsLink(rdvaUri: string, cameraId: number): string {
    // format: http://<host>:2206/live/<ID>.m3u8
    return `https://s.${rdvaUri}/live/${cameraId}.m3u8`;
  }

  getHlsArchiveLink(rdvaUri: string, cameraId: number): string {
    // format: http://<host>:2206/archive/ID.m3u8?<ID>:<YYYY-MM-DD-HH-mm-ss>:<duration>
    // duration: seconds
    return `https://s.${rdvaUri}/archive/${cameraId}.m3u8`;
  }

  getImageThumbnail(rdvaUri: string, cameraId: number): string {
    // format: http://<host>:2205/tn?<ID>.m3u8
    return `https://s.${rdvaUri}/tn?${cameraId}`;
  }

  getCameraById(cameraId: number): Observable<Camera> {
    return this.get<Camera>(`/cameras/${cameraId}`, 1);
  }

  getCameraRDAConfigById(cameraId: number): Observable<ICamerasRdaConfig> {
    return this.get<ICamerasRdaConfig>(`/cameras/${cameraId}/key`, 1);
  }
}
