import { Component, ElementRef, EventEmitter, forwardRef, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectSearch } from '@app/shared/models';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  useExisting: forwardRef(() => SelectSearchComponent),
  multi: true
};

@Component({
  selector: 'app-select-search',
  templateUrl: './select-search.component.html',
  styleUrls: ['./select-search.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class SelectSearchComponent implements OnInit, OnDestroy, OnChanges, ControlValueAccessor {
  @Input() title!: string;
  @Input() valuesList!: SelectSearch[];
  @Input() selectedValue!: SelectSearch;
  @Input() required: boolean;
  @Input() loading: boolean;
  @Input() showEmptyOption: boolean;
  @Input() description: string;
  @Input() nonePadding: boolean;
  @Input() noneMargin: boolean;
  @Input() isFilterActive: boolean = false;
  @Input() isSortedActive: boolean = false;
  @Input() disabled: boolean;

  private filteredOptions = new BehaviorSubject<SelectSearch[]>([]);
  filterInput: UntypedFormControl = new UntypedFormControl();
  filteredOptions$ = this.filteredOptions.asObservable();

  readonly emptySelectSearch: SelectSearch = {
    text: '',
    value: null
  };

  @Output() private valueSelected: EventEmitter<SelectSearch> = new EventEmitter();
  @Output() private searchChanged: EventEmitter<{ value: string }> = new EventEmitter();
  @Output() private closed: EventEmitter<void> = new EventEmitter();


  private innerValue: SelectSearch;
  private onDestroy$: Subject<void> = new Subject();
  private onTouchedCallback: () => void = () => { };
  private onChangeCallback: (_: any) => void = () => { };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.valuesList) {
      const sortedSelectSearch = this.getSortedList(changes.valuesList.currentValue)
      this.filteredOptions.next(sortedSelectSearch)
    }
  }

  ngOnInit() {
    this.initFilterInputChanges();
    this.setSelectedValue();
  }

  ngOnDestroy() {
    this.closed.emit();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  get value(): SelectSearch {
    return this.innerValue;
  }

  set value(v: SelectSearch) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  onBlur() {
    this.onTouchedCallback();
  }

  writeValue(value: SelectSearch) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  onSelect(selectItem: SelectSearch) {
    this.valueSelected.emit(selectItem);
  }

  displayFn(item: SelectSearch): string {
    if (!item) {
      return '';
    }

    return item.text;
  }

  private initFilterInputChanges(): void {
    this.filterInput.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        map((inputValue: string | SelectSearch) => {
          if (!inputValue) {
            this.emitSearchChanged('');
            return this.valuesList;
          }

          if (typeof inputValue === 'string') {
            this.emitSearchChanged(inputValue);
            const filteredList = this.getFilteredList(inputValue);
            return this.getSortedList(filteredList);
          }

          this.emitSearchChanged(inputValue.text);
          const filteredList = this.getFilteredList(inputValue.text);
          return this.getSortedList(filteredList);

        }),
        takeUntil(this.onDestroy$)
      )
      .subscribe((filteredList: SelectSearch[]) => this.filteredOptions.next(filteredList));
  }

  private getSortedList(items: SelectSearch[]): SelectSearch[] {
    if(this.isSortedActive){
      return items.sort((str1, str2) => str1.text.localeCompare(str2.text));
    }
    return items;
  }

  private getFilteredList(inputValue: string): SelectSearch[] {
    if (this.isFilterActive) {
      return this.valuesList
        .filter(value => value.text.toLowerCase().includes(inputValue.toLowerCase()));
    }

    return this.valuesList;
  }

  private emitSearchChanged(value: string): void {
    this.searchChanged.emit({ value });
  }

  private setSelectedValue(): void {
    if (this.selectedValue && this.selectedValue.text) {
      this.filterInput.setValue(this.selectedValue);
    }
  }
}
