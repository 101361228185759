<div class="timeline-container">
  <div class="timeline" #timeline
    (mousemove)="onTimelineMove($event)" 
    (mouseleave)="onTimelineLeave()"
    (mouseenter)="onTimelineEnter()"
    (mouseup)="onTimelineUp($event)"
  >
    <rd-video-timeline-canvas></rd-video-timeline-canvas>
    <rd-video-timeline-slider
      (eventMouseUp)="onTimelineUp($event)"
    ></rd-video-timeline-slider>
    <rd-video-timeline-tooltip></rd-video-timeline-tooltip>
  </div>
  <div class="time-labels">
    <span class="text-monospace">{{ formatDate( (selectedDateTime$ | async), 'HH:mm:ss') }}</span>
  </div>
</div>
