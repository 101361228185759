<div class="service-rdas">
  <div class="service-rdas-row">
    <div class="service-rdas-row-col">
      <div class="service-rdas-row-col-item" *ngIf="xlWDownBreakpoint$ | async">
        <p class="service-rdas-row-col-item__title">
          {{ 'service.intercom.field.status' | translate }}
        </p>
        <p class="service-rdas-row-col-item-value">
          <app-button-link
            *ngIf="!intercom.active"
            [link]="link"
            [color]="red"
            class="link"
          >
            {{ 'service.intercom.message.why_offline' | translate }}
          </app-button-link>
          <mat-chip
            class="value"
            [disabled]="true"
            [selectable]="false"
            color="{{ intercom.active ? 'success' : 'error' }}"
          >
            {{ intercom.active ? ('service.intercom.enum.active.online' | translate) : ('service.intercom.enum.active.offline' | translate) }}
          </mat-chip>
        </p>
      </div>

      <div class="service-rdas-row-col-item">
        <p class="service-rdas-row-col-item__title">
          {{ 'service.intercom.field.uid' | translate }}
        </p>
        <p class="service-rdas-row-col-item-value service-rdas-row-col-item-value--strong">
          {{ intercom.uid }}
        </p>
      </div>

      <div class="service-rdas-row-col-item">
        <p class="service-rdas-row-col-item__title">
          {{ 'service.intercom.field.type' | translate }}
          <br>
          <a target="_blank"
            *ngIf="intercom.intercomType?.instructionUrl"
            [href]="intercom.intercomType.instructionUrl"
          >
            {{ 'service.intercom.link.connection_scheme' | translate }}
          </a>
        </p>
        <p class="service-rdas-row-col-item-value service-rdas-row-col-item-value--strong">
          {{ intercom.intercomType?.name || "" }}
          <mat-icon
            *ngIf="invalidIntercomType"
            class="service-rdas-row-col-item-value__error-ic"
          >
            error
          </mat-icon>
        </p>
      </div>

      <ng-container [ngSwitch]="isIpIntercom()">
        <ng-container *ngSwitchCase="true">
          <div class="service-rdas-row-col-item">
            <p class="service-rdas-row-col-item__title">
              {{ 'service.intercom.field.connection_method' | translate }}
            </p>
            <p class="service-rdas-row-col-item-value">
              {{ intercom.mode | ipRdaConnectionType }}
            </p>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="false">
          <div class="service-rdas-row-col-item" *ngIf="intercom.ipAddress">
            <p class="service-rdas-row-col-item__title">
              {{ 'service.intercom.field.ip' | translate }}
            </p>
            <p class="service-rdas-row-col-item-value">
              {{ intercom.ipAddress }}
            </p>
          </div>
        </ng-container>
      </ng-container>
    </div>

    <div class="service-rdas-row-col">
      <div class="service-rdas-row-col-item">
        <p class="service-rdas-row-col-item__title">
          {{ 'service.intercom.field.last_activity' | translate }}
        </p>
        <p class="service-rdas-row-col-item-value">
          {{ intercom.lastHeartBeatTime | date: 'dd.MM.yyyy HH:mm:ss'}}
        </p>
      </div>

      <ng-container [ngSwitch]="isIpIntercom()">
        <ng-container *ngSwitchCase="true">
          <div class="service-rdas-row-col-item" *ngIf="pbxOnRda[intercom.uid]?.pbxOnRdaUid">
            <p class="service-rdas-row-col-item__title">
              {{ 'service.intercom.field.pbx_on_rda' | translate }}
            </p>
            <p class="service-rdas-row-col-item-value">
              {{ pbxOnRda[intercom.uid]?.pbxOnRdaUid }}
            </p>
          </div>

          <div class="service-rdas-row-col-item" *ngIf="livenessUrl">
            <p class="service-rdas-row-col-item__title">
              {{ 'service.intercom.field.liveness_url' | translate }}
            </p>
            <p class="service-rdas-row-col-item-value">
              {{ livenessUrl }}
            </p>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="false">
          <div class="service-rdas-row-col-item" *ngIf="intercom.translation">
            <p class="service-rdas-row-col-item__title">
              {{ 'service.intercom.field.translation' | translate }}
            </p>
            <p class="service-rdas-row-col-item-value">
              {{ intercom.translation }}
            </p>
          </div>

          <div class="service-rdas-row-col-item">
            <p class="service-rdas-row-col-item__title">
              {{ 'service.intercom.field.shield' | translate }}
            </p>
            <p class="service-rdas-row-col-item-value">
              {{ intercom.versionInfoShield }}
              <mat-icon
                *ngIf="!intercom.versionInfoShield"
                class="service-rdas-row-col-item-value__error-ic"
              >
                error
              </mat-icon>
            </p>
          </div>

          <div class="service-rdas-row-col-item" *ngIf="rdaPro && rdaPro != intercom.uid">
            <p class="service-rdas-row-col-item__title">
              {{ 'service.intercom.field.rda_pro_uid' | translate }}
            </p>
            <p class="service-rdas-row-col-item-value">
              {{ rdaPro }}
            </p>
          </div>
        </ng-container>
      </ng-container>

      <div *ngIf="intercomPanelsNames.length > 0" class="service-rdas-row-col-item">
        <p class="service-rdas-row-col-item__title">
          {{ 'service.intercom.field.intercom_panels' | translate }}
        </p>
        <p class="service-rdas-row-col-item-value">
          {{ intercomPanelsNames.join(', ') }}
        </p>
      </div>
    </div>
  </div>

  <div class="service-rdas-row">
    <div class="service-rdas-state">
      <div *ngIf="!(xlWDownBreakpoint$ | async)" class="service-rdas-state-status">
        <app-button-link
          *ngIf="!intercom.active"
          [link]="link"
          color="red"
          class="link"
        >
          {{ 'service.intercom.message.why_offline' | translate }}
        </app-button-link>
        <mat-chip
          class="value"
          [disabled]="true"
          [selectable]="false"
          color="{{ intercom.active ? 'success' : 'error' }}"
        >
          {{ intercom.active ? ('service.intercom.enum.active.online' | translate) : ('service.intercom.enum.active.offline' | translate) }}
        </mat-chip>
      </div>

      <div class="service-rdas-state-actions">
        <app-service-intercom-update-button
          class="service-rdas-state-actions__button"
          *ngIf="intercom.active && someComponentHasUpdate && !isIpIntercom()"
          [status]="updateStatus$ | async"
          (upgradeClicked)="onUpgrade(rdaPro && rdaPro != intercom.uid ? rdaPro : intercom.uid)"
        ></app-service-intercom-update-button>

        <button
          mat-icon-button
          color="primary"
          matTooltip="{{ 'service.intercom.tooltip.info' | translate }}"
          [matTooltipPosition]="'above'"
          (click)="onInfo()"
          class="service-rdas-state-actions__button"
        >
          <mat-icon svgIcon="info"></mat-icon>
        </button>

        <button
          mat-icon-button
          color="primary"
          matTooltip="{{ 'service.intercom.tooltip.edit' | translate }}"
          [matTooltipPosition]="'above'"
          (click)="onEdit(intercom)"
          class="service-rdas-state-actions__button"
        >
          <mat-icon aria-label="Edit">edit</mat-icon>
        </button>

        <button
          mat-icon-button
          color="error"
          matTooltip="{{ 'service.intercom.tooltip.delete' | translate }}"
          [matTooltipPosition]="'above'"
          (click)="onDelete()"
          class="service-rdas-state-actions__button"
        >
          <mat-icon aria-label="Delete">delete</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
