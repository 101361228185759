import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {
  ServiceCreateRequest,
  ServiceCreateResponse,
  ServiceInfoResponse
} from '@app/shared/entities/rd/services/models';
import {PagedResponse} from '@app/shared/models';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ServiceApiService} from './service-api.service';
import {ICommonMethodOfDataReceipt} from '@app/views/support-service/models/commonDataProtocol';
import {TranslateService} from '@ngx-translate/core';
import { HumanErrorTranslationService } from '@app/shared/services/human-error-translation.service';
import { SortData } from '@app/shared/models/sort-order.type';

@Injectable({
  providedIn: 'root'
})
export class SoftwareIntercomService extends ServiceApiService implements ICommonMethodOfDataReceipt {
  constructor(
    httpClient: HttpClient,
    humanErrorTranslationService: HumanErrorTranslationService,
    translate: TranslateService
  ) {
    super(httpClient, humanErrorTranslationService, translate);
  }

  create(request: ServiceCreateRequest): Observable<ServiceCreateResponse> {
    return this.post<ServiceCreateRequest, ServiceCreateResponse>(
      '/services/software_intercoms', 1, request, {headers: this.headers}
    );
  }

  getPage(
    page: number = 0,
    size: number = 10,
    filter?: string, 
    sort?: SortData
  ): Observable<PagedResponse<ServiceInfoResponse>> {
    const params: HttpParams = this.preparGetPageParams(page, size, filter, sort);

    return this.get<PagedResponse<ServiceInfoResponse>>(
      '/services/software_intercoms', 1, {params}
    )
      .pipe(
        map((pagedResponse: PagedResponse<ServiceInfoResponse>) =>
          this.preparePageResponse(pagedResponse)
        )
      );
  }
}
