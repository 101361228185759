import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { ResolutionBreakpoint } from '@app/shared/services/resolution/resolution-breakpoints.enum';
import { ResolutionService } from '@app/shared/services/resolution/resolution.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

export enum ConfirmType {
  EmtyData = 'EmtyData',
  FilledData = 'FilledData'
}

export interface Output {
  confirm: boolean;
}

@Component({
  selector: 'app-apartment-video-camera-confirmation',
  templateUrl: './apartment-video-camera-confirmation.component.html',
  styleUrls: ['./apartment-video-camera-confirmation.component.scss'],
  standalone: true,
  imports: [CommonModule, ButtonModule, TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ApartmentVideoCameraConfirmationComponent {
  public readonly confirmType = ConfirmType;

  constructor(
    private dynamicDialogRef: DynamicDialogRef,
    private dynamicDialogConfig: DynamicDialogConfig,
    private translateService: TranslateService,
    private resolutionService: ResolutionService,
  ) {
    this.dynamicDialogConfig.styleClass = 'apartment-video-camera__modal';
    if (this.resolutionService.getBreakpointState(ResolutionBreakpoint.SM_W_DOWN)) {
      this.dynamicDialogConfig.height = '100%';
      this.dynamicDialogConfig.width = '100%';
      this.dynamicDialogConfig.styleClass = `${this.dynamicDialogConfig.styleClass} apartment-video-camera__modal--full-screen`;
    }
  }

  public get cameraConfirmType(): ConfirmType {
    return this.dynamicDialogConfig.data?.confirmType;
  }

  public get additionalData(): string | null {
    return this.dynamicDialogConfig.data?.additionalData;
  }

  public get confirmButtonLabel(): string {
    switch (this.cameraConfirmType) {
      case ConfirmType.FilledData: {
        return this.translateService.instant('apartments_video_surveillance.popup.confirmation_fill_data')
      }
      case ConfirmType.EmtyData: {
        return this.translateService.instant('apartments_video_surveillance.popup.confirmation_emty_data');
      }
      default:
        return this.translateService.instant('apartments_video_surveillance.popup.confirmation_fill_data');
    }
  }

  public get confirmButtonType(): string {
    switch (this.cameraConfirmType) {
      case ConfirmType.EmtyData: {
        return 'p-button-danger';
      }
      case ConfirmType.FilledData: {
        return 'p-button-success';
      }
      default:
        return 'p-button-success';
    }
  }

  public onConfirmAction(): void {
    this.dynamicDialogRef.close({confirm: true});
  }
}
