import {Component, NgZone, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ServiceApiService} from '@app/shared/entities/rd';
import {BehaviorSubject, of, Subject} from 'rxjs';
import {catchError, mergeMap, takeUntil, filter} from 'rxjs/operators';
import {DialogService} from 'primeng/dynamicdialog';
import {
  ApartmentVideoAddService,
  ApartmentVideoAddServiceComponent
} from '@app/views/services/submodules/apartment-video-surveillance/components/modals';
import {PersonalSurveillance} from '@app/shared/entities/rd/services/models/personal-surveillance.model';
import {
  TableAVSCameraStatuses,
  TableAVSConverted,
  TableAVSEnum
} from '@app/views/services/submodules/apartment-video-surveillance/pipes';
import {GridRenderAction} from '@app/shared/components/render/render.component';
import {
  ListStatusesCellComponent
} from '@app/shared/components/table-with-server-pagination/twsp-cells-components/list-statuses-cell/list-statuses-cell.component';
import {
  SingleStatusCellComponent
} from '@app/shared/components/table-with-server-pagination/twsp-cells-components/single-statuse-cell/single-status-cell.component';
import {
  AvsGridActionsComponent
} from '@app/views/services/submodules/apartment-video-surveillance/components/sipmple/avs-grid-actions/avs-grid-actions.component';
import {Router} from '@angular/router';
import {
  ApartmentVideoRemoveService,
  ApartmentVideoRemoveServiceComponent
} from '@app/views/services/submodules/apartment-video-surveillance/components/modals/apartment-video-remove-service/apartment-video-remove-service.component';
import {PaginationDataView} from '@app/shared/components/pagination-data-view/pagination-data-view.component';
import {SnackbarService} from '@app/shared/components';
import {
  TextWithActionComponent
} from '@app/shared/components/table-with-server-pagination/twsp-cells-components/text-with-action/text-with-action.component';
import {PhonePipe} from '@app/shared/pipes';
import {
  ApartmentVideoSurveillanceActionsService
} from '@app/views/services/submodules/apartment-video-surveillance/services/apartment-video-surveillance-actions.service';

export namespace ApartmentVideoSurveillancePage {
  export interface ExtDeleteOutput extends ApartmentVideoRemoveService.Output {
    connectionId?: number;
    errorWhenDisconnect?: true;
  }
}

@Component({
  selector: 'app-apartment-video-surveillance-page',
  templateUrl: './apartment-video-surveillance-page.component.html',
  styleUrls: ['./apartment-video-surveillance-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ApartmentVideoSurveillancePageComponent implements OnInit, OnDestroy {
  public tableData = new BehaviorSubject<PersonalSurveillance.ServiceResponse[]>([]);
  public paginationDataViewConfig: PaginationDataView.Config<TableAVSConverted>;
  public waitTableData$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private phonePipe = new PhonePipe();
  private viewerState: PaginationDataView.State<TableAVSConverted>;
  private paginationState2: PaginationDataView.PaginatorConfig;
  private destroy = new Subject<void>();

  constructor(
    private translateService: TranslateService,
    private serviceApiService: ServiceApiService,
    private dialogService: DialogService,
    private snackbar: SnackbarService,
    private router: Router,
    private ngZone: NgZone,
    private apartmentVideoSurveillanceActionsService: ApartmentVideoSurveillanceActionsService,
  ) {
    this.calculatePageSize();
  }

  public ngOnInit(): void {
    this.paginationDataViewConfig = this.getPaginationDataViewConfig();
  }

  public ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  public onAddAction(): void {
    this.dialogService.open(
      ApartmentVideoAddServiceComponent,
      {
        header: this.translateService.instant('apartments_video_surveillance.popup.adding_service'),
        data: {},
        width: '499px',
        closeOnEscape: true,
        dismissableMask: true,
        closable: true,
        resizable: false,
        draggable: true,
      }
    ).onClose
      .pipe(takeUntil(this.destroy))
      .subscribe((output: ApartmentVideoAddService.Output) => {
        if (output) {
          this.snackbar.showMessage(
            this.translateService.instant('apartments_video_surveillance.popup.creating_service_success'),
            'success'
          );
          this.loadLists();
        }
      });
  }

  public viewerOutput($event: PaginationDataView.State<TableAVSConverted>): void {
    let needToLoadBySort = false;
    if (this.viewerState) {
      needToLoadBySort = $event?.sortBy?.order !== this.viewerState?.sortBy?.order || $event?.sortBy?.field !== this.viewerState?.sortBy?.field;
    }
    const neeToLoadByFilter = this.paginationState2.page !== $event.page ||
      this.paginationState2.pageSize !== $event.pageSize ||
      this.viewerState?.search !== $event.search;
    const neeToLoadBySearch = this.viewerState?.search !== $event.search;
    this.viewerState = $event;
    this.paginationState2.page = $event.page;
    this.paginationState2.pageSize = $event.pageSize;
    if (needToLoadBySort || neeToLoadByFilter || neeToLoadBySearch || $event.init) {
      this.loadLists();
    }
  }

  private navigateToOwner(id: number): void {
    this.router.navigate(['abonents/subscriber', id]);
  }

  private getPaginationDataViewConfig(): PaginationDataView.Config<TableAVSConverted> {
    const getTableStr = (path: string) => this.translateService.instant(`apartments_video_surveillance.table.${path}`);
    return {
      id: 'avs-page-table',
      key: 'id',
      emptyMessage: this.translateService.instant('apartments_video_surveillance.page.no_data_found'),
      showSearch: true,
      sizes: [
        {field: TableAVSEnum.phone, width: 20, sizeType: '%'},
        {field: TableAVSEnum.name, width: 40, sizeType: '%'},
        {field: TableAVSEnum.camerasCount, width: 150},
        {field: TableAVSEnum.status, width: 150},
        {field: TableAVSEnum.camerasStatuses, width: 250},
        {field: TableAVSEnum.actions, width: 150},
      ],
      header: [
        {name: getTableStr('subscriber'), field: TableAVSEnum.phone},
        {name: getTableStr('name'), field: TableAVSEnum.name, sort: true},
        {name: getTableStr('cameras'), field: TableAVSEnum.camerasCount},
        {name: getTableStr('service_status'), field: TableAVSEnum.status},
        {name: getTableStr('cameras_status'), field: TableAVSEnum.camerasStatuses},
        {name: '', field: TableAVSEnum.actions, isAction: true},
      ],
      grid: [
        {
          field: TableAVSEnum.phone,
          useRender: true,
          renderOptions: <GridRenderAction<TextWithActionComponent, string, TableAVSConverted>>{
            component: TextWithActionComponent,
            updater: (componentInstance, data, rowData: TableAVSConverted) => {
              componentInstance.text = this.phonePipe.transform(data?.toString()) || '-//-';
              componentInstance.actionsRight = [
                {
                  label: '', icon: 'pi pi-arrow-right', action: () => this.navigateToOwner(rowData.raw.abonent.id),
                  styleClass: 'p-button-rounded p-button-info p-button-outlined', className: 'grid-actions grid-actions--gray'
                }
              ];
            }
          }
        },
        {field: TableAVSEnum.name},
        {field: TableAVSEnum.camerasCount},
        {
          field: TableAVSEnum.status,
          useRender: true,
          renderOptions: <GridRenderAction<SingleStatusCellComponent, boolean, TableAVSConverted>>{
            component: SingleStatusCellComponent,
            updater: (componentInstance, data, rowData) => {
              componentInstance.isActive = !data;
            }
          }
        },
        {
          field: TableAVSEnum.camerasStatuses,
          useRender: true,
          renderOptions: <GridRenderAction<ListStatusesCellComponent, TableAVSCameraStatuses[], TableAVSConverted>>{
            component: ListStatusesCellComponent,
            updater: (componentInstance, data, rowData) => {
              componentInstance.statuses = data;
            }
          }
        },
        {
          field: TableAVSEnum.actions,
          useRender: true,
          isAction: true,
          renderOptions: <GridRenderAction<AvsGridActionsComponent, TableAVSConverted, TableAVSConverted>>{
            component: AvsGridActionsComponent,
            updater: (componentInstance, data, rowData) => {
              this.ngZone.runOutsideAngular(() => {
                componentInstance.output.pipe(takeUntil(this.destroy)).subscribe((output) => {
                  if (output.navigate) {
                    this.router.navigate(['/personal_surveillance', rowData.id]);
                  }
                  if (output.delete) {
                    this.deleteService(rowData);
                  }
                });
              });
            }
          },
        },
      ],
      paginator: this.paginationState2
    };
  }

  private loadLists(): void {
    this.waitTableData$.next(true);
    const sorting = this.viewerState?.sortBy?.order !== 'none' && typeof this.viewerState?.sortBy?.field === 'string' ?
      `${this.viewerState.sortBy.field},${this.viewerState.sortBy.order}` :
      '';
    const filterString = this.viewerState?.search ? `filter=${this.viewerState?.search}` : '';
    this.serviceApiService.getPersonalSurveillanceList<PersonalSurveillance.ServiceResponse[]>(
      this.paginationState2.page,
      this.paginationState2.pageSize,
      filterString, sorting
    )
      .pipe(
        takeUntil(this.destroy),
        catchError((err) => {
          this.snackbar.showMessage(err.error.error, 'error');
          return of(null);
        }),
        filter((response) => !!response)
      )
      .subscribe((response) => {
        this.calculatePageSize(response.numberOfElements, response.totalElements, response.number, this.paginationState2.pageSize);
        this.tableData.next(response.content);
        this.paginationDataViewConfig = this.getPaginationDataViewConfig();
        this.waitTableData$.next(false);
      });
  }

  private deleteService(rowData: TableAVSConverted): void {
    this.dialogService.open(
      ApartmentVideoRemoveServiceComponent,
      {
        header: this.translateService.instant('apartments_video_surveillance.popup.disable_service'),
        data: {
          name: rowData[TableAVSEnum.name],
          confirmType: ApartmentVideoRemoveService.ConfirmType.RemovingAVService
        },
        width: '499px',
        closeOnEscape: true,
        dismissableMask: true,
        closable: true,
        resizable: false,
        draggable: true,
      }
    ).onClose
      .pipe(
        takeUntil(this.destroy),
        mergeMap((output: ApartmentVideoSurveillancePage.ExtDeleteOutput) =>
          this.apartmentVideoSurveillanceActionsService.removeService(rowData.raw.id, output)),
        filter((data) => !!data)
      )
      .subscribe((response) => {
        this.snackbar.showMessage(
          this.translateService.instant('apartments_video_surveillance.popup.deleting_service_success'),
          'success'
        );
        this.loadLists();
      });
  }

  private calculatePageSize(rows = 0, totalRecords = 0, page = 0, pageSize = 10): void {
    this.paginationState2 = {
      show: true,
      dropdownValues: [5, 10, 20, 50, 100],
      rows,
      totalRecords,
      page,
      pageSize,
      first: 0,
      pageLinkSize: 10,
      currentPageReportTemplate: '{first} - {last} ' + this.translateService.instant('shared.prime_base_table.of') + ' {totalRecords}',
    };
  }
}
