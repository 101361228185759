<app-login-content
    [loading]="loading$ | async"
    (login)="onSubmit($event.request)"
>

   <!-- Зима! До новых встречь!!! :) -->
  <ng-container *ngIf="!environment.vivotec" snowflakes>
    <app-snow></app-snow>
  </ng-container>

</app-login-content>
