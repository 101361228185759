import { Injectable } from '@angular/core';
import { SignUpsPageCounts } from '@app/views/abonents/models';
import { Dictionary } from '@app/shared/helpers';
import { SignUpStatus, WaitAbonentSignUpsCount } from '@app/shared/models';
import { RequestsService } from '@app/shared/services';
import { BodyAdditionField, IPrimeTableConfig } from '@app/shared/components/table-with-server-pagination/table-with-server-pagination.component';
import { BehaviorSubject } from 'rxjs';
import { TabModeOptions } from '../components/pages/sign-ups-page/store';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class SignUpsPageService {
    private tableConfig = new BehaviorSubject<IPrimeTableConfig>(this.getBaseTableConfig());
    public tableConfig$ = this.tableConfig.asObservable();

    constructor(private signUpsService: RequestsService, private translate: TranslateService) { }

    async getPageCounters(): Promise<SignUpsPageCounts> {
        let generalCounts: Dictionary<SignUpStatus>[];
        let waitAbonentSignUpsCounts: WaitAbonentSignUpsCount;

        try {
            generalCounts = await this.signUpsService.getSignUpsCount().toPromise();
            waitAbonentSignUpsCounts = await this.signUpsService.getWaitAbonentSignUpsCount().toPromise();
        } catch (error) {
            throw error;
        }

        const pageCounts: SignUpsPageCounts = {
            unprocessed: generalCounts[SignUpStatus.UNPROCESSED],
            processed: generalCounts[SignUpStatus.PROCESSED],
            waitAbonentConfirmation: {
                total: generalCounts[SignUpStatus.WAIT_ABONENT_CONFIRMATION],
                olderThanWeek: waitAbonentSignUpsCounts.olderThanWeek,
                olderThanMonth: waitAbonentSignUpsCounts.olderThanMonth
            }
        };

        return pageCounts;
    }

    updateTableConfig(linkName: string): void {
        const baseTableConfig = this.getBaseTableConfig();

        const tabModeOptionColumns: string[] = TabModeOptions[linkName].columns;
        const sortColumns: string[] = TabModeOptions[linkName].sortColumns;

        const body = baseTableConfig.body.filter(header => tabModeOptionColumns.includes(header.field));
        const headers = baseTableConfig.header
            .filter(header => {
                const isFieldIncludes = tabModeOptionColumns.includes(header.field);
                if(isFieldIncludes) {
                    header.sort = sortColumns.includes(header.field);
                }
                
                return isFieldIncludes;
            });

        baseTableConfig.header = headers;
        baseTableConfig.body = body;

        this.tableConfig.next(baseTableConfig)
    };

    private getBaseTableConfig(): IPrimeTableConfig {
        return {
            key: 'id',
            showActionColumn: false,
            showCustomActionColumn: true,
            showDoublePaginator: false,
            header: [
                { name: this.translate.instant('sign_ups.page.field.phone'), field: 'phone', className: '', sort: false },
                { name: this.translate.instant('sign_ups.page.field.full_address'), field: 'fullAddress', className: '', sort: false },
                { name: this.translate.instant('sign_ups.page.field.flat_number'), field: 'flatNumber', className: '', sort: false },
                {
                    name: this.translate.instant('sign_ups.page.field.is_virtual'),
                    field: 'isVirtual',
                    className: '',
                    sort: false
                },
                { name: this.translate.instant('sign_ups.page.field.services'), field: 'services', className: '', sort: false },
                {
                    name: this.translate.instant('sign_ups.page.field.ad_campaign'),
                    field: 'adCampaign',
                    className: '',
                    sort: false
                },
                {
                    name: this.translate.instant('sign_ups.page.field.ad_source'),
                    field: 'adSource',
                    className: '',
                    sort: false
                },
                {
                    name: this.translate.instant('sign_ups.page.field.created_at'),
                    field: 'createdAt',
                    className: '',
                    sort: false
                }
            ],
            body: [
                {
                    field: 'phone',
                    className: '',
                    additionField: {
                        type: BodyAdditionField.requestType,
                        field: 'signUpType'
                    },
                    showArrow: true,
                },
                { field: 'fullAddress', className: '', style: 'color: #757685;' },
                {
                    field: 'flatNumber',
                    additionField: {
                        type: BodyAdditionField.status,
                        field: 'isFreeAddress'
                    },
                    className: 'pt-w-20'
                },
                {
                    field: 'isVirtual',
                    className: '',
                    icon: '/assets/icons/ic-tube-dark.svg'
                },
                { field: 'services', isListView: true, className: '' },
                { field: 'adCampaign', className: '' },
                { field: 'adSource', className: '' },
                { field: 'createdAt', className: '' }
            ]
        };
    }
}
