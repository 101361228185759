<div class="apartment-video-camera-connect__wrapper">
  <div class="apartment-video-camera-connect__content apartment-video-camera-connect__content__wrapper">
    <div class="content__helps__wrapper">
      <p-button [label]="'apartments_video_surveillance.popup.how_to_configure' | translate"
                styleClass="p-button-secondary" class="content__helps--action content__helps--action-1"
                (onClick)="onHowToConfigureAction()"></p-button>
    </div>

    <div *ngIf="!loaded" class="content__form__wrapper">
      <p-skeleton width="100%" height="2rem"></p-skeleton>
      <div class="content__form__field__row__wrapper">
        <p-skeleton class="content__skeleton-row" width="100%" height="2rem"></p-skeleton>
        <p-skeleton class="content__skeleton-row" width="100%" height="2rem"></p-skeleton>
      </div>
      <p-skeleton width="100%" height=".5rem"></p-skeleton>
      <p-skeleton width="100%" height="2rem"></p-skeleton>
      <div class="content__form__field__row__wrapper">
        <p-skeleton width="5rem" height="3.25rem"></p-skeleton>
        <div class="content__form__wrapper" style="flex: 1">
          <p-skeleton width="100%" height="2rem"></p-skeleton>
          <p-skeleton width="100%" height=".5rem"></p-skeleton>
        </div>
      </div>
    </div>
    <div *ngIf="showLoading" class="content__loader__wrapper">
      <p-progressSpinner></p-progressSpinner>
    </div>

    <ng-container *ngIf="loaded">
      <div *ngIf="loaded" [formGroup]="form" class="content__form__wrapper">
        <h2 class="content--title">{{'apartments_video_surveillance.popup.fill_in_parameters' | translate}}</h2>
        <div class="content__form__field__wrapper">
          <div class="p-float-label">
              <input 
                pInputText 
                id="uri" 
                formControlName="uri" 
                class="content__form__field--item"
                autocomplete="off"
                autocorrect="off"
                autocapitalize="off"
                spellcheck="false"
              /> 
              <label htmlFor="uri">URI*</label> 
          </div>
          <small class="content__form__field--label-small">
            {{'apartments_video_surveillance.popup.rtsp_link_hint_about_secure_procedure' | translate}}
          </small>
        </div>
        
        <div class="content__form__field__wrapper">
          <div class="content__form__field__row__wrapper">
            <div class="p-float-label">
              <input 
                pInputText 
                id="login" 
                formControlName="login" 
                class="content__form__field--item"
                autocomplete="off"
                autocorrect="off"
                autocapitalize="off"
                spellcheck="false"
              />
              <label htmlFor="login">{{'apartments_video_surveillance.popup.login' | translate}}</label>
            </div>
            
            <div class="p-float-label">
              <input 
                pInputText 
                id="password" 
                formControlName="password" 
                class="content__form__field--item"
                autocomplete="off"
                autocorrect="off"
                autocapitalize="off"
                spellcheck="false"
              />
              <label htmlFor="password">{{'apartments_video_surveillance.popup.password' | translate}}</label>
            </div>
          </div>
          <label class="content__form__field--label-small">{{'apartments_video_surveillance.popup.rtsp_link_description' | translate}}</label>
        </div>

        <div class="content__form__field__wrapper">
          <div class="content__form__field__row__wrapper">
            <p-inputSwitch formControlName="recordAndBroadcast"></p-inputSwitch>
            <div class="content__form__field__wrapper">
              <label class="content__form__field--label">{{'apartments_video_surveillance.popup.record_and_broadcast' | translate}}</label>
              <label class="content__form__field--label-small">{{'apartments_video_surveillance.popup.record_and_broadcast_hint' | translate}}</label>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="apartment-video-camera-connect__footer__wrapper">
    <p-button 
      [disabled]="!isFormValid"
      [label]="(isEdit ? 'apartments_video_surveillance.popup.edit' : 'apartments_video_surveillance.popup.connect') | translate"
      [styleClass]="isFormValid ? '' : 'p-button-secondary'" class="apartment-video-camera-connect__footer--fw-action"
      (onClick)="onSaveAction()">
    </p-button>
  </div>
</div>
