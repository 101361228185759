<ng-template 
  [ngTemplateOutlet]="template || defaultTemplate"
  [ngTemplateOutletContext]="{
    isActive: isActive,
    name: name,
    icon: icon,
    onClick: onClick,
    isSelectButton: isSelectButton
  }"
></ng-template>

<ng-template #defaultTemplate>
  <button 
    id="changeMode" 
    type="button" 
    class="container" 
    (click)="onClick()" 
    [class.select-button]="isSelectButton"
    [class.round-button]="isRoundButton"
    [class.active]="isActive">
    <img *ngIf="icon" src="{{ icon }}">
    {{ name }}
  </button>
</ng-template>