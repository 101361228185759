import { Injectable } from "@angular/core";
import { SecureCameraInfo, SplittedFormData, UriData } from "../models/apartment-video-surveillance.models";

// Standar URI example: rtsp://admin:tD3ga@109.102.991_a_1V
// Rare URI example: rtsp://192.168.5.204:554/user=admin5&password=12345а&channel=1&stream=0.sdp? 
export enum UriType {
  'standart' = 'standart',
  'rare' = 'rare',
  'other' = 'other'
}

@Injectable()
export class ApartmentVideoSurveillanceUriService {
  private readonly rareUriLoginSeparator = 'user=';
  private readonly rareUriPasswordSeparator = 'password=';
  private readonly rtspProtocol = 'rtsp://';

  public getSecureCameraInfo(uri: string): SecureCameraInfo {
    const uriType: UriType = this.getUriType(uri);

    if (uriType === UriType.other) {
      return {secureUri: null, uri, login: '', password: '', splittedUri: null};
    }

    const data: SplittedFormData | null = this.splitUri(uri, uriType);
    const secureUri: string | null = data ? data.splittedUri.protocol + data.splittedUri.restUri : null;

    return {
      secureUri,
      login: data?.currentLogin ? data.currentLogin : '',
      password: data?.currentPassword ? data.currentPassword : '',
      splittedUri: data?.splittedUri ?? null,
      uri,
    };
  }

  public replaceDataInUri(splittedUri: UriData, newData: string, isLogin: boolean, uri: string): string {
    const uriType: UriType = this.getUriType(uri);

    if (uriType === UriType.standart) {
      if (isLogin) {
        return `${splittedUri.protocol}${newData}:${splittedUri.password}@${splittedUri.restUri}`;
      }

      return `${splittedUri.protocol}${splittedUri.login}:${newData}@${splittedUri.restUri}`;
    }

    if (uriType === UriType.rare) {
      if (isLogin) {
        return `${splittedUri.protocol}${newData}&${this.rareUriPasswordSeparator}${splittedUri.password}&${splittedUri.restUri}`;
      }

      return `${splittedUri.protocol}${splittedUri.login}&${this.rareUriPasswordSeparator}${newData}&${splittedUri.restUri}`;
    }

    return uri;
  }

  private splitUri(uri: string, uriType: UriType): SplittedFormData | null {
    if (uriType === UriType.standart) {
      return this.splitStandartUri(uri);
    }

    if (uriType === UriType.rare) {
      return this.splitRareFormatUri(uri);
    }

    return null;
  }

  private splitStandartUri(uri: string): SplittedFormData | null {
    const colonAfterLoginIndex = this.getTheColonPosition(uri);
    const atAfterPasswordIndex = this.getTheAtPosition(uri);
    const protocolEnd = this.getUriFirstPartIndex(uri);

    if (colonAfterLoginIndex === -1 || atAfterPasswordIndex === -1 || protocolEnd === -1) {
      return null;
    }

    const login = uri.slice(protocolEnd, colonAfterLoginIndex);
    const password = uri.slice(colonAfterLoginIndex + 1, atAfterPasswordIndex);
    return {
      currentLogin: login,
      currentPassword: password,
      splittedUri: {
        protocol: uri.slice(0, protocolEnd),
        login,
        password,
        restUri: uri.slice(atAfterPasswordIndex + 1)
      },
    };
  }

  private splitRareFormatUri(uri: string): SplittedFormData | null {
    const loginStartIndex = uri.indexOf(this.rareUriLoginSeparator) + this.rareUriLoginSeparator.length;
    const loginEndIndex = uri.indexOf(`&${this.rareUriPasswordSeparator}`);
    const passwordStartIndex = uri.indexOf(`&${this.rareUriPasswordSeparator}`) + this.rareUriPasswordSeparator.length + 1;
    const passwordEndIndex = uri.indexOf('&', passwordStartIndex);
    const protocol = this.getUriFirstPartIndex(uri);

    if (
      loginStartIndex === -1 || loginEndIndex === -1 ||
      passwordStartIndex === -1 || passwordEndIndex === -1 ||
      protocol === -1
    ) {
      return null;
    }

    const login = uri.substring(loginStartIndex, loginEndIndex);
    const password = uri.substring(passwordStartIndex, passwordEndIndex);

    return {
      splittedUri: {
        protocol: uri.slice(0, loginStartIndex),
        login,
        password,
        restUri: uri.slice(passwordEndIndex + 1)
      },
      currentLogin: login,
      currentPassword: password
    };
  }

  private getTheColonPosition(uri: string): number {
    const protocolPosition = this.getUriFirstPartIndex(uri);
    let colonPosition = uri.indexOf(':', protocolPosition);

    if (protocolPosition === -1 || colonPosition === -1) {
      return -1;
    }

    const difference = colonPosition - protocolPosition;
    if (difference > 0) {
      return colonPosition;
    }

    while((uri[colonPosition] === ':' || uri[colonPosition] === ' ') && colonPosition <= uri.length) {
      colonPosition += 1;
    }

    if (uri[colonPosition] !== '@' && colonPosition < uri.length && uri[colonPosition - 1] === ' ') {
      return colonPosition - 2;
    }

    if (uri[colonPosition] !== '@' && colonPosition < uri.length) {
      return colonPosition - 1;
    }

    if (uri[colonPosition] === '@' && colonPosition < uri.length) {
      return (colonPosition - protocolPosition) === 1 ? protocolPosition : -1;
    }

    return -1;
  }

  private getTheAtPosition(uri: string): number {
    const protocolPosition = this.getUriFirstPartIndex(uri);
    const colonPosition = uri.indexOf(':', protocolPosition);
    let atPosition = uri.indexOf('@', protocolPosition);

    if (atPosition === -1 || colonPosition === -1 || protocolPosition === -1) {
      return -1;
    }

    while(atPosition < colonPosition) {
      atPosition += 1;
      atPosition = uri.indexOf('@', atPosition);
    }

    if (atPosition < uri.length) {
      return atPosition;
    }
  }

  private getUriFirstPartIndex(uri: string): number {
    const uriType = this.getUriType(uri);

    if (uriType === UriType.standart) {
      const startPosition = uri.lastIndexOf(this.rtspProtocol);
      const endPosition = startPosition + this.rtspProtocol.length;

      return endPosition ? endPosition : -1;
    }

    if (uriType === UriType.rare) {
      const endPosition = uri.indexOf(this.rareUriLoginSeparator);

      return endPosition ? endPosition : -1;
    }

    return -1;
  }

  private getUriType(uri: string): UriType {
    const hasRtspProtocol = /.*rtsp:\/\//.test(uri);
    const isStandartUriFormat = /.*rtsp:\/\/(.*):(.*)@(.*)/ .test(uri);
    const hasAtCharacter = /@/.test(uri);
    const hasSpecialCharacter = /(?=.*user=)(?=.*&password=)/i.test(uri);

    if (hasRtspProtocol && hasAtCharacter && isStandartUriFormat && !hasSpecialCharacter) {
      return UriType.standart;
    }

    const isRareUriFormat = /(?<!^).+user=.*password=.*.(?!$)/.test(uri);
    if (hasRtspProtocol && hasSpecialCharacter && isRareUriFormat) {
      return UriType.rare;
    }

    return UriType.other;
  }
}
