import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from "@angular/core";
import { DecorateUntilDestroy, takeUntilDestroyed } from "@app/shared/rxjs/operator/take-until-destroyed";
import { TranslateService } from "@ngx-translate/core";
import { distinctUntilChanged, map } from "rxjs/operators";
import { StoreService } from "../../service/video-store/store.service";

@DecorateUntilDestroy()
@Component({
  selector: 'rd-video-time-manage-control',
  templateUrl: './time-manage-control.html',
  styleUrls: ['./time-manage-control.scss']
})

export class RdVideoTimeManageControlComponent {
  @Input() step: number;
  @Input() type: 'prev' | 'next';

  constructor(private storeService: StoreService, private translate: TranslateService) { }

  onClick(): void {
    const currentTime = this.storeService.getPlayerCurrentTime()

    if (this.type === 'prev') {
      this.storeService.fireNewPlayerCurrentTime(currentTime - this.step);
    } else {
      this.storeService.fireNewPlayerCurrentTime(currentTime + this.step);
    }
  }

  getLabel(): string {
    if (this.type === 'prev') {
      return `- ${this.step} ${this.translate.instant('shared.video_player.plyr.template.button.second')}`
    }
    return `+ ${this.step} ${this.translate.instant('shared.video_player.plyr.template.button.second')}`
  }

  getIcon(): string {
    if (this.type === 'prev') {
      return 'pi pi-angle-double-left'
    }
    return 'pi pi-angle-double-right'
  }

  getIconPosition(): string {
    if (this.type === 'prev') {
      return 'left'
    }
    return 'right'
  }
}
