import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  ApartmentVideoServiceActivityComponent,
  ApartmentVideoServicePageComponent,
  ApartmentVideoServiceSettingsComponent,
  ApartmentVideoSurveillancePageComponent
} from '@app/views/services/submodules/apartment-video-surveillance/components';
import {CardModule} from 'primeng/card';
import {InputTextModule} from 'primeng/inputtext';
import {ReactiveFormsModule} from '@angular/forms';
import {ButtonModule} from 'primeng/button';
import {
  ApartmentVideoAddServiceComponent,
  ApartmentVideoCameraConnectComponent
} from '@app/views/services/submodules/apartment-video-surveillance/components/modals';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {ApartmentVideoGridConverterPipe} from '@app/views/services/submodules/apartment-video-surveillance/pipes';
import {TranslateModule} from '@ngx-translate/core';
import {PaginationDataViewComponent} from '@app/shared/components/pagination-data-view/pagination-data-view.component';
import {
  AvsGridActionsComponent
} from '@app/views/services/submodules/apartment-video-surveillance/components/sipmple/avs-grid-actions/avs-grid-actions.component';
import {SkeletonModule} from 'primeng/skeleton';
import {NavbarModule} from '@app/shared/ui';
import {TooltipModule} from 'primeng/tooltip';
import {CamerasTableContainerModule} from '@app/shared/containers';
import {ServiceActivityModule} from '@app/views/services/components';
import {DelegationComponent} from '@app/views/services/submodules/shared/delegation/delegation.component';
import {
  ApartmentVideoSurveillanceActionsService
} from '@app/views/services/submodules/apartment-video-surveillance/services/apartment-video-surveillance-actions.service';
import { ApartmentVideoSurveillanceUriService } from './services/apartment-video-surveillance-uri.service';
import { ApartmentVideoCameraConfirmationComponent } from './components/modals/apartment-video-camera-confirmation/apartment-video-camera-confirmation.component';


@NgModule({
  declarations: [
    ApartmentVideoSurveillancePageComponent,
    ApartmentVideoServicePageComponent,
    ApartmentVideoServiceActivityComponent,
    ApartmentVideoServiceSettingsComponent,
    ApartmentVideoGridConverterPipe
  ],
  imports: [
    CommonModule,
    ApartmentVideoAddServiceComponent,
    ApartmentVideoCameraConnectComponent,
    CardModule,
    AvsGridActionsComponent,
    InputTextModule,
    ReactiveFormsModule,
    ButtonModule,
    TranslateModule,
    PaginationDataViewComponent,
    SkeletonModule,
    NavbarModule,
    TooltipModule,
    CamerasTableContainerModule,
    ServiceActivityModule,
    DelegationComponent,
  ],
  providers: [DialogService, DynamicDialogRef, ApartmentVideoSurveillanceActionsService, ApartmentVideoSurveillanceUriService]
})
export class ApartmentVideoSurveillanceModule {
}
