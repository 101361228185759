<div class="apartment-video-camera__wrapper">
  <div class="apartment-video-camera__content__wrapper">
        <ng-container [ngSwitch]="cameraConfirmType">
          <ng-container *ngSwitchCase="confirmType.FilledData">
            <div class="apartment-video-camera--title">
              {{'apartments_video_surveillance.popup.confirmation_filled_data_main_text' | translate}}
            </div>
            <div class="apartment-video-camera--text">
              {{'apartments_video_surveillance.popup.confirmation_filled_data_text' | translate }}
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="confirmType.EmtyData">
            <div class="apartment-video-camera--title">
              <ng-container *ngIf="!additionalData.login && additionalData.password">
                <div>
                  {{'apartments_video_surveillance.popup.confirmation_emty_data_main_text' | translate : 
                    {
                    text: 'apartments_video_surveillance.popup.confirmation_emty_login' | translate
                    } 
                  }}
                </div>
              </ng-container>
  
              <ng-container *ngIf="additionalData.login && !additionalData.password">
                <div>
                  {{'apartments_video_surveillance.popup.confirmation_emty_data_main_text' | translate : 
                    {
                    text: 'apartments_video_surveillance.popup.confirmation_emty_password' | translate
                    } 
                  }}
                </div>
              </ng-container>
  
              <ng-container *ngIf="!additionalData.login && !additionalData.password">
                <div>
                  {{'apartments_video_surveillance.popup.confirmation_emty_data_main_text' | translate : {
                    text: 
                    ('apartments_video_surveillance.popup.confirmation_emty_login' | translate) + ' ' + 
                    ('apartments_video_surveillance.popup.and' | translate) + ' ' + 
                    ('apartments_video_surveillance.popup.confirmation_emty_password' | translate)
                    } }}
                </div>
              </ng-container>
            </div>
            <div class="apartment-video-camera--text">
              {{'apartments_video_surveillance.popup.confirmation_emty_data_text' | translate }}
            </div>
          </ng-container>
        </ng-container>
      
  </div>
  <div class="apartment-video-camera__footer__wrapper">
    <p-button 
      [label]="confirmButtonLabel" 
      [styleClass]="confirmButtonType"
      class="apartment-video-camera__footer--fw-action"
      (onClick)="onConfirmAction()"
      >
    </p-button>
  </div>
</div>