import {HttpErrorResponse} from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FileResponse} from '@app/shared/api';
import {SnackbarService} from '@app/shared/components';
import {LoaderService, PreviosQueryParams, PreviosQueryType} from '@app/shared/entities/common';
import {ReportsApiService, ReportsUtils, SignUpsReportRequest} from '@app/shared/entities/rd';
import {Dictionary, parseError} from '@app/shared/helpers';
import {
  PagedResponse,
  SignUpRequestV2,
  SignUpResponse,
  SignUpStatus,
  WaitAbonentSignUpsCount
} from '@app/shared/models';
import {RequestsService} from '@app/shared/services';
import {NavbarLink} from '@app/shared/ui';
import {SignUpsPageCounts, SignUpsPageMode} from '@app/views/abonents/models';
import {ComponentStore, tapResponse} from '@ngrx/component-store';
import {from, Observable, Subject} from 'rxjs';
import {switchMap, takeUntil, tap, withLatestFrom} from 'rxjs/operators';
import {SignUpsPageTableOptions} from '../models';
import {signUpsPageInitialState, SignUpsPageState} from './sign-ups-page.state';
import {TranslateService} from '@ngx-translate/core';

export const TabModeOptions = {
  [SignUpsPageMode.UNPROCESSED]: {
    columns: ['phone', 'fullAddress', 'flatNumber', 'services', 'status', 'isVirtual', 'adCampaign', 'adSource', 'createdAt', 'actions'],
    sortColumns: ['phone', 'fullAddress', 'adCampaign', 'adSource', 'createdAt'],
    statuses: [SignUpStatus.UNPROCESSED], 
  },
  [SignUpsPageMode.PROCESSED]: {
    columns: ['phone', 'fullAddress', 'flatNumber', 'services', 'status', 'isVirtual', 'adCampaign', 'adSource', 'createdAt', 'updatedAt', 'actions'],
    sortColumns: ['phone', 'fullAddress', 'adCampaign', 'adSource', 'createdAt'],
    statuses: [SignUpStatus.PROCESSED]
  },
  [SignUpsPageMode.WAIT_ABONENT_CONFIRMATION]: {
    columns: ['phone', 'fullAddress', 'flatNumber', 'services', 'status', 'isVirtual', 'adCampaign', 'adSource', 'createdAt', 'updatedAt', 'actions'],
    sortColumns: ['phone', 'fullAddress', 'adCampaign', 'adSource', 'createdAt'],
    statuses: [SignUpStatus.WAIT_ABONENT_CONFIRMATION]
  },
  [SignUpsPageMode.COMPLETE]: {
    columns: ['phone', 'fullAddress', 'flatNumber', 'services', 'isVirtual', 'createdAt', 'adCampaign', 'adSource', 'updatedAt', 'progress', 'actions'],
    sortColumns: ['phone', 'fullAddress', 'adCampaign', 'adSource', 'createdAt'],
    statuses: [SignUpStatus.CONNECTED, SignUpStatus.DELEGATED, SignUpStatus.REJECTED]
  },
  [SignUpsPageMode.BUILDING]: {
    withoutCompany: true,
    columns: ['fullAddress', 'isVirtual', 'updatedAt', 'createdAt', 'actions'],
    sortColumns: ['fullAddress', 'createdAt']
  },
  [SignUpsPageMode.BY_REGION]: {
    withoutCompany: true,
    byRegion: true,
    columns: ['fullAddress', 'isVirtual', 'updatedAt', 'createdAt', 'actions'],
    sortColumns: ['fullAddress', 'createdAt']
  },
  [SignUpsPageMode.ALL]: {
    columns: ['phone', 'fullAddress', 'flatNumber', 'services', 'progress', 'status', 'isVirtual', 'adCampaign', 'adSource', 'createdAt', 'updatedAt', 'actions'],
    statuses: [SignUpStatus.UNPROCESSED, SignUpStatus.PROCESSED, SignUpStatus.WAIT_ABONENT_CONFIRMATION, SignUpStatus.CONNECTED, SignUpStatus.DELEGATED, SignUpStatus.REJECTED]
  },
  [SignUpsPageMode.ACTIVE]: {
    columns: ['phone', 'fullAddress', 'flatNumber', 'services', 'progress', 'status', 'isVirtual', 'adCampaign', 'adSource', 'createdAt', 'updatedAt', 'actions'],
    statuses: [SignUpStatus.UNPROCESSED, SignUpStatus.PROCESSED, SignUpStatus.WAIT_ABONENT_CONFIRMATION]
  }
};

@Injectable()
export class SignUpsPageStore extends ComponentStore<SignUpsPageState> implements OnDestroy {
  readonly filterInputValue$: Observable<string> = this.select((state: SignUpsPageState) => state.filterInputValue);
  readonly page$: Observable<number> = this.select((state: SignUpsPageState) => state.page);
  readonly links$: Observable<NavbarLink[]> = this.select((state: SignUpsPageState) => state.links);
  readonly tableOptions$: Observable<SignUpsPageTableOptions> = this.select((state: SignUpsPageState) => state.tableOptions);
  readonly activeLink$: Observable<SignUpsPageMode> = this.select((state: SignUpsPageState) => state.activeLink);
  readonly savedActiveLink$: Observable<SignUpsPageMode> = this.select((state: SignUpsPageState) => state.savedActiveLink);
  readonly signUps$: Observable<SignUpResponse[]> = this.select((state: SignUpsPageState) => state.signUps);
  readonly signUpsTotal$: Observable<number> = this.select((state: SignUpsPageState) => state.totalCount);
  readonly waitAbonentSignUpsCount$: Observable<WaitAbonentSignUpsCount> = this.select((state: SignUpsPageState) => state.waitAbonentSignUpsCount);
  readonly showWarning$: Observable<boolean> = this.select((state: SignUpsPageState) => state.showWarning);
  readonly loading$: Observable<boolean> = this.select((state: SignUpsPageState) => state.loading);

  readonly getSignUps = this.effect((options$: Observable<{
      page: number,
      searchString?: string,
      tableOptions?: SignUpsPageTableOptions
    }>) =>
      options$.pipe(
        withLatestFrom(this.tableOptions$),
        switchMap(([options, tableOptions]) => {
          this.updateLoading(true);
          this.updateSignUpsState(null);
          const customTableOptions: SignUpsPageTableOptions = options.tableOptions ?? tableOptions;

          return this.requestsService.getSignUpsV2List(
            options.page,
            10,
            {
              searchString: options.searchString,
              withoutCompany: customTableOptions.withoutCompany,
              status: customTableOptions.statuses,
              byRegion: customTableOptions.byRegion
            }
          )
            .pipe(
              tapResponse(
                (response: PagedResponse<SignUpResponse>) => {
                  this.updateLoading(false);
                  this.updateSignUpsState(response.content);
                  this.updateTotalCountState(response.totalElements);
                },
                (error: HttpErrorResponse) => {
                  this.updateLoading(false);
                  this.snackbar.showMessage(
                    this.translate.instant('sign_ups.page.message.get.failed', {
                      text: parseError(error)
                    })
                  );
                }
              )
            );
        })
      )
  );

  readonly updateSignUp = this.effect((options$: Observable<{
      signUpId: number,
      request: SignUpRequestV2,
      withoutNotification?: boolean,
      dialogRefToClose?: MatDialogRef<any>,
      reloadPage?: boolean
    }>) =>
      options$.pipe(
        withLatestFrom(
          this.tableOptions$,
          this.signUps$
        ),
        switchMap(([options, tableOptions, signUps]) => {
          this.loader.loaderState = {state: true};

          return this.requestsService.updateSignUpV2(options.signUpId, options.request)
            .pipe(
              tapResponse(
                () => {
                  this.loader.loaderState = {state: false};

                  if (!options.withoutNotification) {
                    let message: string;

                    switch (options.request.status) {
                      case SignUpStatus.PROCESSED:
                        message = this.translate.instant('sign_ups.page.message.update.processed.success');
                        break;
                      case SignUpStatus.CONNECTED:
                        message = this.translate.instant('sign_ups.page.message.update.connected.success');
                        break;
                      case SignUpStatus.DELEGATED:
                        message = this.translate.instant('sign_ups.page.message.update.delegated.success');
                        break;
                      case SignUpStatus.REJECTED:
                        message = this.translate.instant('sign_ups.page.message.update.rejected.success');
                        break;
                      default:
                        message = this.translate.instant('sign_ups.page.message.update.default.success');
                        break;
                    }

                    this.snackbar.showMessage(message, 'success');
                  }

                  if (options.reloadPage) {
                    this.getSignUps({page: 0, tableOptions});
                    this.prepareLinksUsingFilter({linkIdx: 0});
                  } else {
                    this.updateSignUpState(signUps.filter(signUp => signUp.signUpId !== options.signUpId));
                  }

                  if (options.dialogRefToClose) {
                    options.dialogRefToClose.close();
                  }

                  this.getCounts();
                },
                (error: HttpErrorResponse) => {
                  this.loader.loaderState = {state: false};

                  let message: string;

                  switch (options.request.status) {
                    case SignUpStatus.PROCESSED:
                      message = this.translate.instant('sign_ups.page.message.update.processed.failed', {
                        text: parseError(error)
                      });
                      break;
                    case SignUpStatus.REJECTED:
                      message = this.translate.instant('sign_ups.page.message.update.rejected.failed', {
                        text: parseError(error)
                      });
                      break;
                    default:
                      message = this.translate.instant('sign_ups.page.message.update.default.failed', {
                        text: parseError(error)
                      });
                      break;
                  }

                  this.snackbar.showMessage(message);
                }
              )
            );
        })
      )
  );

  readonly getReport = this.effect((options$: Observable<{ request: SignUpsReportRequest }>) =>
    options$.pipe(
      switchMap((options) => {
        this.loader.loaderState = {state: true};

        return this.reportsApiService.getSignUpsReport(options.request)
          .pipe(
            tapResponse(
              (response: FileResponse) => {
                this.loader.loaderState = {state: false};
                ReportsUtils.downLoadFile(response.arrayBuffer, decodeURI(response.fileName));
                this.snackbar.showMessage(
                  this.translate.instant('sign_ups.page.message.get_report.success'), 'success'
                );
                this.dialog.closeAll();
              },
              (error: HttpErrorResponse) => {
                this.loader.loaderState = {state: false};
                this.snackbar.showMessage(
                  this.translate.instant('sign_ups.page.message.get_report.failed', {
                    text: parseError(error)
                  })
                );
              }
            )
          );
      })
    )
  );

  readonly getCounts = this.effect((options$: Observable<void>) =>
    options$.pipe(
      withLatestFrom(this.links$),
      switchMap(([action, links]: [void, NavbarLink[]]) =>
        from(this.getPageCounters())
          .pipe(
            tapResponse(
              (response: SignUpsPageCounts) => {
                const waitAbonentSignUpsCount: WaitAbonentSignUpsCount = response.waitAbonentConfirmation;
                const showWarning = waitAbonentSignUpsCount.olderThanWeek > 0 || waitAbonentSignUpsCount.olderThanMonth > 0;

                // TODO: Need refactoring
                const tmp = signUpsPageInitialState.links;
                tmp.forEach(link => {
                  link.text = this.translate.instant(link.text);
                });

                links[0].text = tmp[0].text
                  + `${response.unprocessed ? '' : ''}`;
                links[1].text = tmp[1].text
                  + `${response.processed ? '' : ''}`;
                links[2].text = tmp[2].text
                  + `${response.waitAbonentConfirmation?.total ? '' : ''}`;

                this.updateSignUpsCountsState({waitAbonentSignUpsCount, showWarning, links});
              },
              (error: HttpErrorResponse) => {
                this.snackbar.showMessage(
                  this.translate.instant('sign_ups.page.message.get_counts.failed', {
                    text: parseError(error)
                  })
                );
              }
            )
          )
      )
    )
  );

  readonly updateSignUpsState = this.updater((state: SignUpsPageState, signUps: SignUpResponse[]) => {
    return {...state, signUps};
  });

  readonly updateLoading = this.updater((state: SignUpsPageState, loading: boolean) => {
    return {...state, loading};
  });

  readonly updateSignUpState = this.updater((state: SignUpsPageState, signUps: SignUpResponse[]) => {
    return {...state, signUps: signUps.slice()};
  });

  readonly updateTotalCountState = this.updater((state: SignUpsPageState, totalCount: number) => {
    return {...state, totalCount};
  });

  readonly updateSignUpsCountsState = this.updater((
    state: SignUpsPageState,
    {waitAbonentSignUpsCount, showWarning, links}:
      { waitAbonentSignUpsCount: WaitAbonentSignUpsCount, showWarning: boolean, links: NavbarLink[] }
  ) => {
    return {...state, waitAbonentSignUpsCount, showWarning, links: links.slice()};
  });

  readonly updateSavedActiveLink = this.updater((state: SignUpsPageState, savedActiveLink: SignUpsPageMode) => {
    return {...state, savedActiveLink};
  });

  readonly updateActiveLink = this.updater((state: SignUpsPageState, activeLink: SignUpsPageMode) => {
    return {...state, activeLink};
  });

  readonly updateTableOptions = this.updater((state: SignUpsPageState, tableOptions: SignUpsPageTableOptions) => {
    return {...state, tableOptions};
  });

  readonly updatePage = this.updater((state: SignUpsPageState, page: number) => {
    return {...state, page};
  });

  readonly updateFilterInputValue = this.updater((state: SignUpsPageState, filterInputValue: string) => {
    return {...state, filterInputValue};
  });

  readonly prepareLinksUsingQueryParams = this.effect((options$: Observable<{ queryParams: PreviosQueryParams }>) =>
    options$.pipe(
      withLatestFrom(
        this.filterInputValue$,
        this.page$,
        this.links$,
        this.tableOptions$,
        this.activeLink$,
        this.savedActiveLink$
      ),
      tap(([options, filterInputValue, page, links, tableOptions, activeLink, savedActiveLink]:
             [{
               queryParams: PreviosQueryParams
             }, string, number, NavbarLink[], SignUpsPageTableOptions, SignUpsPageMode, SignUpsPageMode]
      ) => {
        if (options.queryParams.type === PreviosQueryType.SIGN_UPS) {
          if (options.queryParams.query) {
            savedActiveLink = options.queryParams.status ?? links[0].name;
            activeLink = null;
            filterInputValue = options.queryParams.query;
            this.updateFilterInputValue(filterInputValue);
          } else {
            activeLink = options.queryParams.status !== undefined ? options.queryParams.status : links[0].name;
          }

          if (options.queryParams.page !== undefined) {
            page = options.queryParams.page;
            this.updatePage(page);
          }
        }

        if (options.queryParams.status === SignUpsPageMode.ACTIVE) {
          activeLink = SignUpsPageMode.ACTIVE;
          savedActiveLink = SignUpsPageMode.UNPROCESSED;
        }

        if (!activeLink) {
          activeLink = options.queryParams.status === SignUpsPageMode.ACTIVE ? SignUpsPageMode.ACTIVE : SignUpsPageMode.ALL;
        }

        tableOptions = this.prepareTableOptionsFromActiveLink(activeLink);

        this.updateActiveLink(activeLink);
        this.updateSavedActiveLink(savedActiveLink);
        this.updateTableOptions(tableOptions);

        this.getSignUps({
          page,
          tableOptions,
          searchString: options.queryParams.query
        });
      })
    )
  );

  readonly prepareLinksUsingFilter = this.effect((options$: Observable<{ linkIdx?: number, value?: string }>) =>
    options$.pipe(
      withLatestFrom(
        this.page$,
        this.links$,
        this.tableOptions$,
        this.activeLink$,
        this.savedActiveLink$,
      ),
      tap(([options, page, links, tableOptions, activeLink, savedActiveLink]:
             [{
               linkIdx?: number,
               value?: string
             }, number, NavbarLink[], SignUpsPageTableOptions, SignUpsPageMode, SignUpsPageMode]
      ) => {
        if (typeof options.linkIdx === 'number' && options.linkIdx >= 0 && options.linkIdx <= links.length - 1) {
          const updatedActiveLink = links[options.linkIdx].name as SignUpsPageMode;
          if (updatedActiveLink !== activeLink) {
            activeLink = updatedActiveLink;
            tableOptions = this.prepareTableOptionsFromActiveLink(activeLink);
          }
        }
        this.updateActiveLink(activeLink);
        this.updateSavedActiveLink(savedActiveLink);
        this.updateTableOptions(tableOptions);
        this.updatePage(page);
        this.updateFilterInputValue(options.value);
        this.getSignUps({
          page,
          tableOptions,
          searchString: options.value
        });
      })
    )
  );

  private onDestroy$: Subject<void> = new Subject();

  constructor(
    private snackbar: SnackbarService,
    private loader: LoaderService,
    private requestsService: RequestsService,
    private signUpsService: RequestsService,
    private reportsApiService: ReportsApiService,
    private dialog: MatDialog,
    private translate: TranslateService
  ) {
    super(JSON.parse(JSON.stringify(signUpsPageInitialState)));
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  addPageChangeListener(cb: (page: number) => void) {
    this.page$.pipe(takeUntil(this.onDestroy$))
      .subscribe((page: number) => cb(page));
  }

  addFilterInputChangeListener(cb: (filterInputValue: string) => void) {
    this.filterInputValue$.pipe(takeUntil(this.onDestroy$))
      .subscribe((filterInputValue: string) => cb(filterInputValue));
  }

  private async getPageCounters(): Promise<SignUpsPageCounts> {
    let generalCounts: Dictionary<SignUpStatus>[];
    let waitAbonentSignUpsCounts: WaitAbonentSignUpsCount;

    try {
      generalCounts = await this.signUpsService.getSignUpsCount().toPromise();
      waitAbonentSignUpsCounts = await this.signUpsService.getWaitAbonentSignUpsCount().toPromise();
    } catch (error) {
      throw error;
    }

    const pageCounts: SignUpsPageCounts = {
      unprocessed: generalCounts[SignUpStatus.UNPROCESSED],
      processed: generalCounts[SignUpStatus.PROCESSED],
      waitAbonentConfirmation: {
        total: generalCounts[SignUpStatus.WAIT_ABONENT_CONFIRMATION],
        olderThanWeek: waitAbonentSignUpsCounts.olderThanWeek,
        olderThanMonth: waitAbonentSignUpsCounts.olderThanMonth
      }
    };

    return pageCounts;
  }

  private prepareTableOptionsFromActiveLink(activeLink: SignUpsPageMode): SignUpsPageTableOptions {
    return {
      byRegion: TabModeOptions[activeLink]['byRegion'],
      withoutCompany: TabModeOptions[activeLink]['withoutCompany'],
      displayedColumns: TabModeOptions[activeLink]['columns'],
      statuses: TabModeOptions[activeLink]['statuses']
    };
  }
}
