import { Injectable } from '@angular/core';
import { ServicesTypes } from '../models/services-types';
import { Router } from '@angular/router';
import { IServicesConnection } from '../entities/rd/services/services/service-api.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor(private router: Router) { }

  navigateToService(service: IServicesConnection, event?: MouseEvent) {
    switch (service.type) {
      case ServicesTypes.SOFTWARE_INTERCOM:
        this.openLink(`services/software-intercoms/${service.id}`, event);
        break;
      case ServicesTypes.HARDWARE_INTERCOM:
        if (service.bindedSoftwareIntercomeId) {
          this.openLink(`services/software-intercoms/${service.bindedSoftwareIntercomeId}`, event);
        }
        break;
      case ServicesTypes.VIDEO_SURVEILLANCE:
        this.openLink(`services/video-surveillance/${service.id}`, event);
        break;
      case ServicesTypes.GATE:
        this.openLink(`services/gates/${service.id}`, event);
        break;
      default:
        break;
    }
  }

  private openLink(link: string, event?: MouseEvent): void {
    // eslint-disable-next-line import/no-deprecated
    if (event?.metaKey || event?.ctrlKey || event?.which === 2) {
      window.open(link, '_blank');
    } else {
      this.router.navigate([link]);
    }
  }
}
