import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Converter } from '../../helpers/converter';
import { StoreService } from '../../service/video-store/store.service';

@Component({
  selector: 'rd-video-timeline-slider',
  templateUrl: './timeline-slider.html',
  styleUrls: ['./timeline-slider.scss']
})
export class RdVideoTimelineSliderComponent {
  @Output() eventMouseUp = new EventEmitter<MouseEvent>();
  
  position$ = this.storeService.selectSliderPosition$()

  constructor(private storeService: StoreService) { }

  onSliderDragStart(): void {
    this.storeService.updateSliderIsDragging(true)
  }

  @HostListener('document:mouseup', ['$event'])
  onSliderDragEnd(event: MouseEvent): void {
    if (this.storeService.getSliderIsDragging()) {
      this.updateSliderPosition(event);
      this.eventMouseUp.emit(event)
      this.storeService.updateSliderIsDragging(false)
    }
  }

  @HostListener('document:mousemove', ['$event'])
  onSliderMove(event: MouseEvent): void {
    this.updateSliderPosition(event);
  }

  private updateSliderPosition(event: MouseEvent): void {
    if (!this.storeService.getSliderIsDragging()) {
      return;
    }

    const timelineRect = this.storeService.getTimelineElement().getBoundingClientRect();
    const offsetX = event.clientX - timelineRect.left;

    if (offsetX > 0 && offsetX < timelineRect.width) {
      const percentage = Converter.pixelToPercentage(offsetX, timelineRect.width);
      this.storeService.updateSliderPosition(percentage);
    }

    if (offsetX < 0) {
      this.storeService.updateSliderPosition(0);
    }

    if (offsetX > timelineRect.width) {
      this.storeService.updateSliderPosition(100);
    }
  }
}