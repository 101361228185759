<div class="container-main">
  <ng-template 
    [ngTemplateOutlet]="template || defaultTemplate"
    [ngTemplateOutletContext]="{
      selectValueLabel: selectValueLabel$ | async,
      options: options,
      icon: icon,
      onClick: onClick,
      isStaticLabel: isStaticLabel
    }"
  ></ng-template>

  <ng-template #defaultTemplate>
    <rd-video-control *ngIf="!isStaticLabel; else staticLabel"
      [name]="selectValueLabel$ | async"
      [icon]="icon"
      (clickEvent)="onClick()" 
      [isActive]="!isHidden"
    ></rd-video-control>
  </ng-template>

  <div [hidden]="isHidden" class="container">
    <div class="container-wrapper">
      <div *ngIf="!options || options.length === 0" (mousedown)="onClick()">
        <ng-container *ngTemplateOutlet="emptyOptionTemplate"></ng-container>
      </div>          
      
      <rd-video-control *ngFor="let option of options" 
        [name]="option.label"
        [value]="option"
        [isSelectButton]="true"
        (clickEvent)="onSelectChange($event)" 
      ></rd-video-control>
    </div>
  </div>
</div>

<ng-template #staticLabel>
  <rd-video-control
    [name]="name"
    [icon]="icon"
    (clickEvent)="onClick()" 
    [isActive]="!isHidden"
  ></rd-video-control>
</ng-template>
