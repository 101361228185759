import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CreateServicePopupBody, CreateServicePopupComponent, CreateServicePopupResponse, DeleteServicePopupComponent, DeleteServicePopupModel } from '@app/shared/components';
import { LoaderService, PreviosQueryType, RouteDetectionService } from '@app/shared/entities/common';
import { Constants } from '@app/shared/helpers';
import { PageData, ServicesTypes } from '@app/shared/models';
import { ResolutionBreakpoint, ResolutionService } from '@app/shared/services';
import { DialogWrapperData, PaginatedPageWrapperComponent } from '@app/shared/ui';
import { Observable, Subject } from 'rxjs';
import { GatesPageModel } from '../../models';
import { GatesPageFacade } from '../../store';
import {TranslateService} from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { MatSort } from '@angular/material/sort';
import { SortData } from '@app/shared/models/sort-order.type';

@Component({
  selector: 'app-gates-page',
  templateUrl: './gates-page.component.html',
  styleUrls: ['./gates-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GatesPageComponent implements OnInit, OnDestroy {
  readonly previosQueryType: typeof PreviosQueryType = PreviosQueryType;
  readonly displayedColumns: string[] = ['customName', 'rdasState', 'camerasStates', 'actions'];
  private sortData: SortData;
  private onDestroy$ = new Subject();
  protected pageData!: PageData;

  @ViewChild(PaginatedPageWrapperComponent, { static: true }) paginatedPageWrapper: PaginatedPageWrapperComponent;
  loading$: Observable<boolean> = this.gatesPageFacade.loading$;
  gates$: Observable<GatesPageModel[]> = this.gatesPageFacade.gates$;
  pageData$: Observable<PageData> = this.gatesPageFacade.pageData$;
  xlWDownBreakpoint$: Observable<boolean> = this.resolution.getBreakpoint(ResolutionBreakpoint.XL_W_DOWN);

  constructor(
    private dialog: MatDialog,
    private loader: LoaderService,
    private resolution: ResolutionService,
    private gatesPageFacade: GatesPageFacade,
    private routeDetection: RouteDetectionService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.loader.loaderState = { state: false };
    this.prepareQueryParams();
    this.loadServices();

    this.pageData$.pipe(takeUntil(this.onDestroy$))
      .subscribe(pageData => this.pageData = pageData);
  }

  onFilterInputChanged() {
    this.paginatedPageWrapper.paginator.pageIndex = 0;
    this.loadServices();
  }

  onPageChanged() {
    this.loadServices();
  }

  onCreate() {
    const data: DialogWrapperData<CreateServicePopupBody, CreateServicePopupResponse> = {
      title: this.translate.instant('gates.page.create.title'),
      componentName: 'CreateGate',
      body: { serviceType: ServicesTypes.GATE },
      submit: (event: CreateServicePopupResponse) =>
        this.gatesPageFacade.create(
          event.request,
          {
            filterValue: this.paginatedPageWrapper.filterInput.value,
            pageIndex: this.paginatedPageWrapper.paginator.pageIndex
          }
        )
    };

    this.dialog.open(CreateServicePopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      data
    });
  }

  onDelete(serviceId: number, serviceName: string) {
    const pageIndex = this.pageData.numberOfElements === 1 ? 
      this.paginatedPageWrapper.paginator.pageIndex - 1 : this.paginatedPageWrapper.paginator.pageIndex;

    const data: DialogWrapperData<DeleteServicePopupModel, null> = {
      title: this.translate.instant('gates.page.delete.title'),
      componentName: 'DeleteGate',
      body: { serviceType: ServicesTypes.GATE, serviceName: serviceName },
      submit: () =>
        this.gatesPageFacade.delete(
          serviceId,
          {
            pageIndex: pageIndex,
            pageSize: this.paginatedPageWrapper.paginator.pageSize,
            filterValue: this.paginatedPageWrapper.filterInput.value
          }
        )
    };

    this.dialog.open(DeleteServicePopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolution.getBreakpointState(ResolutionBreakpoint.MD_W_DOWN) ? '100%' : '600px',
      data
    });
  }

  onSort(event: MatSort) {
    this.sortData = event.direction === '' ? null : {sortBy: event.active, sortOrder: event.direction};
    this.loadServices();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next('');
    this.onDestroy$.complete();
  }

  private prepareQueryParams() {
    const queryParams = this.routeDetection.getQueryParams();

    if (queryParams.type !== PreviosQueryType.GATES) {
      return;
    }

    this.paginatedPageWrapper.paginator.pageIndex = queryParams.page ?? 0;

    if (queryParams.query) {
      this.paginatedPageWrapper.filterInput.setValue(queryParams.query, { emitEvent: false });
    }
  }

  private loadServices() {
    this.gatesPageFacade.getPage(
      this.paginatedPageWrapper.paginator.pageIndex,
      this.paginatedPageWrapper.paginator.pageSize,
      this.paginatedPageWrapper.filterInput.value, 
      //this.sortData
    );
  }
}
