import { Injectable } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Camera, CameraUtilsService } from '@app/shared/entities/rd';
import { ServicesTypes } from '@app/shared/models';

@Injectable()
export class ServicesHelper {
    constructor(
        private route: ActivatedRoute,
        private cameraUtilsService: CameraUtilsService
    ) { }

    getCorrectServiceRoute(serviceType: ServicesTypes): string {
        switch (serviceType) {
            case ServicesTypes.SOFTWARE_INTERCOM:
                return '/services/software-intercoms/';
            case ServicesTypes.VIDEO_SURVEILLANCE:
                return '/services/video-surveillance/';
            case ServicesTypes.GATE:
                return '/services/gates/';
        }
    }

    getServiceIdFromParams(): number {
        return Number.parseInt(this.route.snapshot.params['id'].match(/([\d]+)/)[0], 10);
    }

    getServiceNameFromParams(): string {
        return this.route.snapshot.queryParams.name;
    }

    getQueryParams(): Params {
        return this.route.snapshot.queryParams;
    }

    filterCamerasWithError(cameras: Camera[]): Camera[] {
        return cameras.filter(camera => !this.cameraUtilsService.isCameraCorrect(camera));
    }
}
