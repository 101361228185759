import { Injectable } from "@angular/core"
import { Store } from "@ngrx/store";
import { BehaviorSubject, Subject } from "rxjs";
import { PeerConnectionState, StateEnum } from "../models/state.model";
import { DescriptionResult, MessageType, PeerConnectionInfo } from "../models/p2p.model";
import { MessageService } from "../message-service/message.service";
import { MediaService } from "../media.service/media.service";
import { MediaStreamService } from "./media-stream.service";
import { iceServersList } from "./ice-servers";

const RECV_ONLY_DIRECTION: RTCRtpTransceiverDirection = 'recvonly';
const SIGNALING_STATE_STABLE: RTCSignalingState = 'stable';
const VIDEO = 'video';
const AUDIO = 'audio';

@Injectable({ providedIn: 'root' })
export class PeerConnectionService {
  private peerConnectionListSubject = new BehaviorSubject<PeerConnectionInfo[]>([]);
  peerConnectionList$ = this.peerConnectionListSubject.asObservable();

  pcNegotiationNeeded$ = new Subject<PeerConnectionInfo>();

  private makingOffer = false;
  private ignoreOffer = false;

  constructor(
    private messageService: MessageService,
    private mediaStreamService: MediaStreamService,
    private mediaService: MediaService
  ) { }

  createPeerConnection(id: number): void {
    const peerConnection = new RTCPeerConnection({
      iceServers: iceServersList
    });

    const peerConnectionInfo: PeerConnectionInfo = {
      id,
      peerConnection,
      state$: new Subject<PeerConnectionState>()
    }

    this.addMedia(id)
    this.onTrack(peerConnectionInfo)
    this.initOnState(peerConnectionInfo);
    this.initOnNegotationNeeded(peerConnectionInfo);
    this.setTransceiverDirection(peerConnectionInfo.peerConnection)

    const currentList = this.peerConnectionListSubject.value;
    this.peerConnectionListSubject.next([...currentList, peerConnectionInfo]);
  }

  async createOffer(pcId: number): Promise<DescriptionResult> {
    const pcInfo = this.getPeerConnectionInfo(pcId);
    if (!pcInfo.peerConnection.localDescription) {
      await pcInfo.peerConnection.setLocalDescription();
    }
    return {
      success: true,
      description: pcInfo.peerConnection.localDescription
    }
  }

  async createAnswer(pcId: number, description?: RTCSessionDescription | null): Promise<DescriptionResult> {
    const pcInfo = this.getPeerConnectionInfo(pcId);

    this.ignoreOffer = this.makingOffer || pcInfo.peerConnection.signalingState !== SIGNALING_STATE_STABLE;

    if (this.ignoreOffer) {
      return {
        success: false
      };
    }

    await this.setRemoteDescription(pcId, description);
    await pcInfo.peerConnection.setLocalDescription();

    return {
      success: true,
      description: pcInfo.peerConnection.localDescription
    };
  }

  async setRemoteDescription(pcId: number, description?: RTCSessionDescription | null): Promise<void> {
    const pcInfo = this.getPeerConnectionInfo(pcId);

    if (description?.type === MessageType.answer) {
      this.ignoreOffer = false;
    }
    if (!description || !pcInfo || !pcInfo.peerConnection) {
      return;
    }

    await pcInfo.peerConnection.setRemoteDescription(description);
  }

  addIceCandidate(pcId: number, candidate?: RTCIceCandidate | null): void {
    const pcInfo = this.getPeerConnectionInfo(pcId);

    try {
      if (!candidate || !pcInfo.peerConnection || !pcInfo.peerConnection.remoteDescription) {
        return;
      }
      pcInfo.peerConnection.addIceCandidate(candidate);
    } catch (error) {
      if (!this.ignoreOffer) {
        console.error('Error add ice candidate', error);
      }
    }
  }

  destroyPeerConnection(id: number): void {
    const { peerConnection } = this.peerConnectionListSubject.value
      .find(value => value.id == id)
    peerConnection.close();

    const filteredPeerConnectionList = this.peerConnectionListSubject.value
      .filter(value => value.id !== id);
    this.peerConnectionListSubject.next(filteredPeerConnectionList);

    this.mediaService.removeMedia(id);
    this.mediaStreamService.removeMediaStream(id)
  }

  destroy(): void {
    this.peerConnectionListSubject.value
      .forEach(value => value.peerConnection.close());
    this.peerConnectionListSubject.next([]);

    this.mediaStreamService.destroy()
  }

  private initOnState(pcInfo: PeerConnectionInfo): void {
    pcInfo.peerConnection.oniceconnectionstatechange = () => {
      pcInfo.state$.next({
        type: StateEnum.iceConnectionState,
        state: pcInfo.peerConnection.iceConnectionState,
      })
    };

    pcInfo.peerConnection.onsignalingstatechange = () => {
      pcInfo.state$.next({
        type: StateEnum.signalingState,
        state: pcInfo.peerConnection.signalingState,
      })
    };

    pcInfo.peerConnection.onconnectionstatechange = () => {
      pcInfo.state$.next({
        type: StateEnum.connectionState,
        state: pcInfo.peerConnection.connectionState,
      })
    };
  }

  private async initOnNegotationNeeded(pcInfo: PeerConnectionInfo): Promise<void> {
    pcInfo.peerConnection.onnegotiationneeded = () => {
      try {
        this.makingOffer = true;
        this.pcNegotiationNeeded$.next(pcInfo)

      } catch (error) {
        console.error('Error during setting local description', error);
      } finally {
        this.makingOffer = false;
      }
    }
  }

  private getPeerConnectionInfo(id: number): PeerConnectionInfo {
    return this.peerConnectionListSubject.value
      .find(value => value.id === id)
  }

  private setTransceiverDirection(pc: RTCPeerConnection): void {
    pc.addTransceiver(VIDEO, {
      'direction': RECV_ONLY_DIRECTION
    })
    pc.addTransceiver(AUDIO, {
      'direction': RECV_ONLY_DIRECTION
    })
  }

  private addMedia(id: number): void {
    const mediaStream = new MediaStream();

    this.mediaService.addMedia({
      id,
      connectMedia: (element: HTMLVideoElement): void => {
        element.srcObject = mediaStream;
      }
    })

    this.mediaStreamService.addMediaStream({
      id,
      mediaStream
    })
  }

  private onTrack(pcInfo: PeerConnectionInfo): void {
    pcInfo.peerConnection.ontrack = event => {
      this.mediaStreamService.addTrack(pcInfo.id, event.track)
    }
  }
}
