import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from "@angular/core";
import { map } from "rxjs/operators";
import { PlayerStateEnum } from "../../models/state.model";
import { StoreService } from "../../service/video-store/store.service";

@Component({
  selector: 'rd-video-control',
  templateUrl: './control.html',
  styleUrls: ['./control.scss']
})
export class RdVideoControlComponent<T> {
  @Input() name: string;
  @Input() value: T;
  @Input() icon: string;
  @Input() isActive: boolean = false;
  @Input() isRoundButton: boolean = false;
  @Input() isSelectButton: boolean = false;

  @ContentChild(TemplateRef) template: TemplateRef<unknown>;

  @Output() clickEvent = new EventEmitter();

  constructor(private storeService: StoreService) { }

  onClick = (): void => {
    this.clickEvent.emit(this.value);
  }
}
