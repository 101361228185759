import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {ResolutionBreakpoint, ResolutionService} from '@app/shared/services';
import {ButtonModule} from 'primeng/button';
import {DropdownModule} from 'primeng/dropdown';
import {InputSwitchModule} from 'primeng/inputswitch';
import {InputTextModule} from 'primeng/inputtext';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {SkeletonModule} from 'primeng/skeleton';
import {catchError, debounceTime, delay, distinctUntilChanged, map, mergeMap, takeUntil } from 'rxjs/operators';
import {of, Subject } from 'rxjs';
import {CameraApiService, PersonalCameraConfig, ServiceApiService} from '@app/shared/entities/rd';
import {SnackbarService} from '@app/shared/components';
import {PersonalSurveillance} from '@app/shared/entities/rd/services/models/personal-surveillance.model';
import {parseError} from '@app/shared/helpers';
import { DialogData, SecureCameraInfo, UriData } from '../../../models/apartment-video-surveillance.models';
import { ApartmentVideoSurveillanceUriService} from '../../../services/apartment-video-surveillance-uri.service';
import { ApartmentVideoCameraConfirmationComponent, ConfirmType } from '../apartment-video-camera-confirmation/apartment-video-camera-confirmation.component';

export namespace VideoCameraConnect {
  export const HowToConnect = 'https://wiki.rosdomofon.com/Поддержка/Техникам_и_администраторам/Камеры/Требования_и_советы_по_настройкам_и_проверке_видеокамер';
  export const LinkPrefix = 'rtsp://';

  export interface Output {
    formData: {
      subscriber: string;
      serviceName: string;
      selectedAccount?: number;
      createNewAccount?: boolean;
      newAccountName?: string;
    };
  }

  export interface Form {
    uri: AbstractControl<string>;
    login: AbstractControl<string>;
    password?: AbstractControl<string>;
    recordAndBroadcast?: AbstractControl<boolean>;
  }

  export enum FormFieldName {
    uri = "uri",
    login = "login",
    password = "password",
    recordAndBroadcast = "recordAndBroadcast"
  }

  export interface DialogData<T> {
    header: string, 
    data: {
      confirmType: ConfirmType, 
      additionalData?: T
    }
  }
}

@Component({
  standalone: true,
  selector: 'app-apartment-video-camera-connect',
  templateUrl: './apartment-video-camera-connect.component.html',
  styleUrls: ['./apartment-video-camera-connect.component.scss'],
  imports: [
    CommonModule,
    ButtonModule,
    DropdownModule,
    InputSwitchModule,
    InputTextModule,
    ProgressSpinnerModule,
    ReactiveFormsModule,
    SkeletonModule,
    TranslateModule
  ],
  providers: [
    ApartmentVideoSurveillanceUriService
  ],
  encapsulation: ViewEncapsulation.None,
})
export class ApartmentVideoCameraConnectComponent implements OnInit, OnDestroy {
  public loaded = false;
  public showLoading = false;
  public form: FormGroup<VideoCameraConnect.Form>;
  private destroy$ = new Subject<void>();
  private savedData: Partial<SecureCameraInfo> = {};
  private readonly defaultFieldMask = '********';

  constructor(
    private dynamicDialogRef: DynamicDialogRef,
    private dynamicDialogConfig: DynamicDialogConfig<DialogData>,
    private resolutionService: ResolutionService,
    private cameraApiService: CameraApiService,
    private snackbar: SnackbarService,
    private serviceApiService: ServiceApiService,
    private translateService: TranslateService,
    private uriService: ApartmentVideoSurveillanceUriService,
    private dialogService: DialogService
  ) {
    this.dynamicDialogConfig.styleClass = 'apartment-video-camera-connect__modal';
    if (this.resolutionService.getBreakpointState(ResolutionBreakpoint.SM_W_DOWN)) {
      this.dynamicDialogConfig.height = '100%';
      this.dynamicDialogConfig.width = '100%';
      this.dynamicDialogConfig.styleClass = `${this.dynamicDialogConfig.styleClass} apartment-video-camera-connect__modal--full-screen`;
    }
  }

  public get isFormValid(): boolean {
    return this.form?.valid;
  }

  public get serviceId(): number {
    return this.dynamicDialogConfig?.data?.serviceId;
  }

  public get cameraInfo(): PersonalSurveillance.CameraFull {
    return this.dynamicDialogConfig?.data?.cameraInfo;
  }

  public get isEdit(): boolean {
    return !!this.dynamicDialogConfig?.data?.isCameraEdit;
  }

  public get uriField() {
    return this.form.get('uri');
  }
  public get loginField() {
    return this.form.get('login');
  }
  public get passwordField() {
    return this.form.get('password');
  }
  public get recordAndBroadcastField() {
    return this.form.get('recordAndBroadcast');
  }

  public ngOnInit(): void {
    of(this.dynamicDialogConfig)
      .pipe(delay(1000), takeUntil(this.destroy$))
      .subscribe(() => {
        this.createForm();
        this.addFormEventListeners();

        if (this.isEdit) {
          this.savedData.uri = this.cameraInfo?.uri;
        }

        this.loaded = true;
      });
  }

  public onSaveAction(): void {
    this.showLoading = true;
    const payload = this.createPayload();
    const isLoginFilled = this.isAuthPayloadFilled(payload.user, VideoCameraConnect.FormFieldName.login);
    const isPasswordFilled = this.isAuthPayloadFilled(payload.password, VideoCameraConnect.FormFieldName.password);

    if (isLoginFilled && isPasswordFilled) {
      const dialogData: VideoCameraConnect.DialogData<unknown> = {
        header: this.translateService.instant('apartments_video_surveillance.popup.confirmation_filled_data_header'), 
        data: {
          confirmType: ConfirmType.FilledData,
        }
      };

      this.openConfirmationDialog(dialogData, payload);
      return;
    }

    if (!isLoginFilled || !isPasswordFilled) {
      const dialogEmtyData: VideoCameraConnect.DialogData<{login: boolean; password: boolean}> = {
        header: this.translateService.instant('apartments_video_surveillance.popup.confirmation_emty_data_header'),
        data: {
          confirmType: ConfirmType.EmtyData,
          additionalData: {login: isLoginFilled, password: isPasswordFilled}
        }
      };

      this.openConfirmationDialog(dialogEmtyData, payload);
      return;
    }
  }

  public onHowToConfigureAction(): void {
    window.open(VideoCameraConnect.HowToConnect, '_blank');
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private createForm(): void {
    const uriValue = this.isEdit ? this.cameraInfo?.uri : VideoCameraConnect.LinkPrefix;

    this.form = new FormGroup<VideoCameraConnect.Form>({
      uri: new FormControl<string>(uriValue, [Validators.required, Validators.max(2000), this.simpleUriValidator()]),
      login: new FormControl<string>(this.isEdit ? this.defaultFieldMask : '', [Validators.maxLength(2000)]),
      password: new FormControl<string>(this.isEdit ? this.defaultFieldMask : '', [Validators.maxLength(2000)]),
      recordAndBroadcast: new FormControl<boolean>(this.isEdit ? this.cameraInfo?.audio : true),
    });
  }

  private createPayload(): Partial<PersonalCameraConfig> {
    const payload: Partial<PersonalCameraConfig> = {
      isPrivate: false,
      personal: true,
      locationId: this.cameraInfo?.location?.id,
      audio: this.recordAndBroadcastField.value
    };

    if (!this.isEdit) {
      return {
        ...payload,
        user: this.savedData.login || null,
        password: this.savedData.password || null,
        uri: this.savedData.secureUri ? this.savedData.secureUri : this.savedData.uri
      };
    }

    if (this.cameraInfo?.isUriSecured) {
      payload.uri = this.cameraInfo.uri;
    }

    if (this.uriField.dirty && this.uriField.touched) {
      payload.uri = this.savedData.secureUri ? this.savedData.secureUri : this.savedData.uri;

      if (this.savedData.secureUri) {
        payload.user = this.savedData.login;
        payload.password = this.savedData.password;
      }
    }

    if (this.loginField.dirty && this.loginField.touched) {
      payload.user = this.savedData.login;
    }

    if (this.passwordField.dirty && this.passwordField.touched) {
      payload.password = this.savedData.password;
    }

    return payload;
  }

  private isAuthPayloadFilled(value: string | undefined, name: string): boolean {
    if (value) {
      return true;
    }

    const field = this.form.get(name);
    if (field.value === this.defaultFieldMask) {
      return true;
    }

    return false;
  }

  private addFormEventListeners(): void {
    this.uriField.valueChanges
      .pipe(
        debounceTime(100),
        distinctUntilChanged(),
        map(value => value.trim()),
        takeUntil(this.destroy$)
      ).subscribe(newUri => {
        if (this.savedData.uri === newUri || newUri === this.savedData.secureUri) {
          return;
        }

        const { secureUri, login, password, splittedUri: splittedUriData } = this.uriService.getSecureCameraInfo(newUri);
        this.updateCameraData(newUri, login, password, splittedUriData, secureUri);

        const shouldUpdateLogin = this.shouldUpdateAuthFields(secureUri, newUri, VideoCameraConnect.FormFieldName.login);
        const shouldUpdatePassword = this.shouldUpdateAuthFields(secureUri, newUri, VideoCameraConnect.FormFieldName.password);

        if (shouldUpdateLogin) {
          this.loginField.patchValue(login);
        }

        if (shouldUpdatePassword) {
          this.passwordField.patchValue(password);
        }
      });

    this.loginField.valueChanges
      .pipe(
        debounceTime(100),
        distinctUntilChanged(),
        takeUntil(this.destroy$)
      ).subscribe(newLogin => {
        this.updateDataAfterControlChanged(newLogin, this.loginField, VideoCameraConnect.FormFieldName.login);
      });

    this.passwordField.valueChanges
      .pipe(
        debounceTime(100),
        distinctUntilChanged(),
        takeUntil(this.destroy$)
      ).subscribe(newPassword => {
        this.updateDataAfterControlChanged(newPassword, this.passwordField, VideoCameraConnect.FormFieldName.password);
      });
  }

  private updateCameraData(uri: string, login: string, password: string, splittedUriData: UriData, secureUri?: string): void {
    this.savedData.uri = uri;
    this.savedData.secureUri = secureUri ?? null;
    this.savedData.password = password;
    this.savedData.login = login;
    this.savedData.splittedUri = splittedUriData;
  }

  private shouldUpdateAuthFields(secureUri: string | null, uri: string, name: string): boolean {
    if (!this.isEdit || secureUri) {
      return true;
    }

    const field = this.form.get(name);
    if (this.isEdit && uri === '' && field.value !== this.defaultFieldMask) {
      return true;
    }

    if (this.isEdit && uri && field.value !== this.defaultFieldMask) {
      return true;
    }

    return false;
  }

  private updateDataAfterControlChanged(
    value: string,
    formControl: AbstractControl<string, string>,
    controlName: VideoCameraConnect.FormFieldName
  ): void {
    if(this.isEdit) {
      if (!this.savedData[controlName] && value?.includes('*')) {
        value = value.replace(/\*/g, '');
        formControl.patchValue(value);
        return;
      }
    }

    if (this.savedData[controlName] !== value) {
      const isLogin = controlName === VideoCameraConnect.FormFieldName.login;
      const newUri = this.uriService.replaceDataInUri(this.savedData.splittedUri, value, isLogin, this.savedData.uri);

      this.savedData.uri = newUri;
      this.savedData[controlName] = value;
      if (this.savedData.splittedUri) {
        this.savedData.splittedUri[controlName] = value;
      }
      this.uriField.patchValue(newUri);
    }
  }

  private openConfirmationDialog(
    dialogData: VideoCameraConnect.DialogData<{login: boolean; password: boolean} | unknown>,
    payload: Partial<PersonalCameraConfig>,
  ) {
    const data = {
      header: dialogData.header,
      data: {
        confirmType: dialogData.data.confirmType,
        additionalData: dialogData.data.additionalData
      },
      width: '430px',
      closeOnEscape: true,
      dismissableMask: true,
      closable: true,
      resizable: false,
      draggable: true,
    };

    this.dialogService.open(ApartmentVideoCameraConfirmationComponent, data).onClose
      .subscribe((response) => {
        if (response) {
          this.sendRequest(payload);
          return;
        }

        this.showLoading = false;
    });
  }

  private sendRequest(payload: Partial<PersonalCameraConfig>): void {
    (
      this.isEdit ?
        this.cameraApiService.updateCamera2(this.cameraInfo?.id, payload) :
        this.cameraApiService.addCamera2(payload)
    )
      .pipe(
        mergeMap((response) => {
          if (this.isEdit) {
            return of(true);
          }

          return this.serviceApiService.connectCamera(this.serviceId, response.id).pipe(
            map(() => true),
            catchError((err) => {
              this.snackbar.showMessage(parseError(err));
              this.showLoading = false;
              return of(null);
            }),
          );
        }),
        takeUntil(this.destroy$),
        catchError((err) => {
          this.snackbar.showMessage(parseError(err));
          this.showLoading = false;
          return of(null);
        }),
      )
      .subscribe((response) => {
        if (response) {
          this.snackbar.showMessage(
            this.isEdit ?
              this.translateService.instant('apartments_video_surveillance.popup.editing_camera_success') :
              this.translateService.instant('apartments_video_surveillance.popup.creating_camera_success'),
            'success'
          );
          this.showLoading = false;
          this.dynamicDialogRef.close({formData: this.form.getRawValue()});
        }
      });
  }

  private simpleUriValidator(): ValidatorFn {
    return (control: AbstractControl<string>): ValidationErrors | null => {
      const value = control?.value?.toLowerCase();
      const pattern = new RegExp(/^((http)|(https)|(rtp)|(rtsp)|(udp)):\/\//gm);
      return value !== VideoCameraConnect.LinkPrefix && pattern.test(value) ? null : {error: true};
    };
  }
}
