<div class="company-sign-up-request-page">
    <div class="company-sign-up-request-page-wrapper">
        <app-company-sign-up-request-form class="company-sign-up-request-page-wrapper-content"
            (submitted)="onSubmit($event)"
        ></app-company-sign-up-request-form>
    </div>
</div>

<ng-container *ngIf="!environment.vivotec" snowflakes>
    <app-snow></app-snow>
</ng-container>