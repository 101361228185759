import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {SnackbarService} from '@app/shared/components/snackbar';
import {ResolutionService} from '@app/shared/entities/common/mobile-query';
import {RdaResponse, RdaUpdateRequest} from '@app/shared/entities/rd/rda';
import {Constants, parseError} from '@app/shared/helpers';
import {EditPopupComponentData} from '@app/shared/models';
import {AddressFormatter} from '@app/shared/services';
import {
  DisconnectAdapter,
  DisconnectAdapterClear,
  GetAvaliableIntercoms,
  UpdateIntercom
} from '@app/shared/store/actions';
import {
  avaliableIntercomsStateLoading,
  avaliableIntercomsStateSuccess,
  disconnectAdapterStateFailure,
  disconnectAdapterStateLoading,
  disconnectAdapterStateSuccess,
  updateIntercomStateFailure,
  updateIntercomStateLoading,
  updateIntercomStateSuccess
} from '@app/shared/store/states';
import {EditPopupComponent} from '@app/shared/templates';
import {State} from '@app/store/reducers';
import {select, Store} from '@ngrx/store';
import {combineLatest, Observable, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, shareReplay, skip, takeUntil} from 'rxjs/operators';
import {AddIntercomFormComponent, DeleteAddressPopupComponent} from '@app/views/intercom/components';
import {TranslateService} from '@ngx-translate/core';
import { SortData } from '@app/shared/models/sort-order.type';
import { SortFieldName } from '@app/views/abonents/components/pages/sign-ups-page/models';

@Component({
  selector: 'rdea-adapters-page',
  templateUrl: './adapters-page.component.html',
  styleUrls: ['./adapters-page.component.scss']
})
export class AdaptersPageComponent implements OnInit, OnDestroy {
  readonly displayedColumns = ['active', 'uid', 'ipAddress', 'address', 'lastHeartBeatTime', 'versionInfoRdaa', 'versionInfoShield', 'versionInfoOs', 'actions'];
  readonly pageSize = 10;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  intercoms$: Observable<{ dataSource: MatTableDataSource<RdaResponse>; totalCount: number; }>;
  filterInput: UntypedFormControl;

  public loading = true;
  private sortData: SortData = {sortBy: SortFieldName.createdAt, sortOrder: 'desc'};
  private onDestroy$: Subject<void>;
  private dialogRef: MatDialogRef<EditPopupComponent>;

  constructor(
    public resolution: ResolutionService,
    private store: Store<State>,
    private dialog: MatDialog,
    private snackbar: SnackbarService,
    private translate: TranslateService,
    private addressFormatter: AddressFormatter
  ) {
    this.filterInput = new UntypedFormControl();
  }

  ngOnInit() {
    this.initFilter();
    this.initStore();
    this.initLoading();
    this.initPagination();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.store.dispatch(new DisconnectAdapterClear());
  }

  onDisconnect(adapter: RdaResponse) {
    const data: EditPopupComponentData = {
      title: this.translate.instant('adapters.page.delete.title'),
      providedData: { adapter: adapter },
      component: DeleteAddressPopupComponent,
      componentName: 'DisconnectRda',
      submit: () => {
        this.store.dispatch(new DisconnectAdapter(adapter));
      }
    };

    this.dialogRef = this.dialog.open(EditPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolution.isMobile ? '100%' : '350px',
      data
    });
  }

  onRegister() {
    const data: EditPopupComponentData = {
      title: this.translate.instant('adapters.page.create.title'),
      providedData: {},
      component: AddIntercomFormComponent,
      componentName: 'AddRda',
      submit: (event: { intercomRegistration: RdaUpdateRequest }) => {
        if (event?.intercomRegistration) {
          this.store.dispatch(new UpdateIntercom(event.intercomRegistration));
        }
      }
    };

    this.dialogRef = this.dialog.open(EditPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolution.isMobile ? '100%' : '350px',
      data
    });
  }

  onSort(event: Sort) {
    this.sortData = event.direction === '' ? {sortBy: SortFieldName.createdAt, sortOrder: 'desc'} :
      {sortBy: event.active, sortOrder: event.direction};

    this.loadIntercoms(this.paginator.pageIndex, this.paginator.pageSize, this.filterInput.value, this.sortData);
  }

  private loadIntercoms(pageNumber: number, pageSize: number, filterInputValue: string, sortData?: SortData) {
    this.loading = true;
    this.store.dispatch(new GetAvaliableIntercoms(pageNumber, pageSize, null, null, null, filterInputValue, sortData));
  }

  private initPagination() {
    this.paginator.page
      .subscribe((e: PageEvent) => this.loadIntercoms(e.pageIndex, this.paginator.pageSize, this.filterInput.value, this.sortData));
    this.loadIntercoms(this.paginator.pageIndex, this.paginator.pageSize, this.filterInput.value, this.sortData);
  }

  private initFilter() {
    this.filterInput.valueChanges.pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(() => {
        this.paginator.pageIndex = 0;
        this.loadIntercoms(this.paginator.pageIndex, this.paginator.pageSize, this.filterInput.value, this.sortData);
      });
  }

  private initStore() {
    this.onDestroy$ = new Subject();
    this.initStoreListeners();
    this.getStoreStates();
  }

  private initStoreListeners() {
    this.store.pipe(select(updateIntercomStateSuccess), takeUntil(this.onDestroy$), skip(1))
      .subscribe(resp => {
        if (resp) {
          if (this.dialogRef) { this.dialogRef.close(); }
          this.loadIntercoms(0,  this.paginator.pageSize, this.filterInput.value, this.sortData);
        }
      });

    this.store.pipe(select(updateIntercomStateFailure), takeUntil(this.onDestroy$), skip(1))
      .subscribe(error => error && this.snackbar.showMessage(
        this.translate.instant('adapters.page.message.update.failed', {
          text: parseError(error)
        })
      ));

    this.store.pipe(select(disconnectAdapterStateSuccess), takeUntil(this.onDestroy$))
      .subscribe(resp => {
        if (resp) {
          if (this.dialogRef) { this.dialogRef.close(); }
          this.snackbar.showMessage(
            this.translate.instant('adapters.page.message.disconnect.success'),
            'success'
          );
          this.loadIntercoms(0,  this.paginator.pageSize, this.filterInput.value, this.sortData);
        }
      });

    this.store.pipe(select(disconnectAdapterStateFailure), takeUntil(this.onDestroy$))
      .subscribe(error => error && this.snackbar.showMessage(
        this.translate.instant('adapters.page.message.disconnect.failed', {
          text: parseError(error)
        })
      ));
  }

  private getStoreStates() {
    this.intercoms$ = this.store.pipe(
      select(avaliableIntercomsStateSuccess),
      map(resp => {
        if (resp) {
          const intercomsWithAddress = resp.intercoms.map(intercom =>
            Object.assign({
              addressString: this.addressFormatter.formatAddress(intercom.address)
            }, intercom)
          );
          return ({ dataSource: new MatTableDataSource(intercomsWithAddress), totalCount: resp.totalCount });
        }
      }),
      shareReplay({
        bufferSize: 1,
        refCount: true
      })
    );
  }

  private initLoading() {
    combineLatest([
      this.store.select(avaliableIntercomsStateLoading),
      this.store.select(updateIntercomStateLoading),
      this.store.select(disconnectAdapterStateLoading)
    ])
      .subscribe((values: boolean[]) => this.loading = values.some((x: boolean) => x));
  }
}
