export interface SignUpsPageTableOptions {
    byRegion: boolean;
    withoutCompany: boolean;
    displayedColumns: string[];
    statuses: string[];
}

export enum SortFieldName {
    'fullAddress' = 'fullAddress',
    'address' = 'address',
    'adSource' = 'adSource',
    'adCampaign' = 'adCampaign',
    'phone' = 'phone',
    'createdAt' = 'createdAt'
}
