import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ApiService} from '@app/shared/api';
import {Dictionary} from '@app/shared/helpers';
import {forkJoin, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import { GetSignUpsV2Query } from '../models/sign-ups/get-sign-ups-v2-request.model';
import { PagedResponse } from '../models/paged-response';
import { SignUpResponse } from '../models/sign-up-response';
import { environment } from 'environments/environment';
import { HumanErrorTranslationService } from './human-error-translation.service';
import { WaitAbonentSignUpsCount } from '../models/sign-ups/wait-abonent-sign-ups-count.model';
import { SignUpRequestV2, SignUpStatus } from '../models';

@Injectable({ providedIn: 'root' })
export class RequestsService extends ApiService {
  readonly contentHeader: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(httpClient: HttpClient, humanErrorTranslationService: HumanErrorTranslationService) {
    super(httpClient, humanErrorTranslationService, environment.abonentsApiUrl, ['v1', 'v2']);
  }

  getSignUpsV2List(
    page: number = 0,
    size: number = 10,
    signUpQuery: GetSignUpsV2Query,
    sortParam?: string
  ): Observable<PagedResponse<SignUpResponse>> {
    let params = new HttpParams()
      .append('page', page.toString())
      .append('size', size.toString());

    if (sortParam) {
      params = params.append('sort', sortParam);
    }

    Object.keys(signUpQuery).map(signUpParam => {
      if (signUpQuery[signUpParam]) {
        params = params.append(signUpParam, signUpQuery[signUpParam]);
      }
    });

    return this.get(`/sign_ups`, 2, { params });
  }

  getWaitAbonentSignUpsCount(size: number = 2000): Observable<WaitAbonentSignUpsCount> {
    const params = { page: 0, size, status: SignUpStatus.WAIT_ABONENT_CONFIRMATION} as any;
    return this.get('/sign_ups', 2, { params }).pipe(
      map((data: PagedResponse<SignUpResponse>) => {
        const counts: WaitAbonentSignUpsCount = { olderThanWeek: 0, olderThanMonth: 0 };
        data.content.map(signUp => {
          if (signUp.createdAt) {
            if (this.getDaysAfterUpdate(signUp.createdAt) > 6 && this.getDaysAfterUpdate(signUp.createdAt) < 31) {
              counts.olderThanWeek++;
            } else if (this.getDaysAfterUpdate(signUp.createdAt) > 30) {
              counts.olderThanMonth++;
            }
          }
        });
        return counts;
      })
    );
  }

  getSignUpsCount(
    statuses: SignUpStatus[] = [SignUpStatus.UNPROCESSED, SignUpStatus.PROCESSED, SignUpStatus.WAIT_ABONENT_CONFIRMATION]
  ): Observable<Dictionary<SignUpStatus>[]> {
    const requests: Observable<PagedResponse<SignUpResponse>>[] =
      statuses.map(status => this.getSignUpsV2List(0, 1, { status: [status] }));

    return forkJoin(requests)
      .pipe(
        map(responses => {
          const dict: Dictionary<SignUpStatus>[] = [];
          responses.forEach((response, idx) => dict[statuses[idx]] = response.totalElements);
          return dict;
        })
      );
  }

  updateSignUpV2(signUpId: number, request: SignUpRequestV2): Observable<void> {
    return this.patch(`/sign_ups/${signUpId}`, 2, request, { headers: this.contentHeader });
  }

  getDaysAfterUpdate(updatedAt: number): number {
    const date1 = new Date(updatedAt);
    const date2 = new Date();

    const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
    const utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());

    return Math.floor((utc2 - utc1) / (1000 * 60 * 60 * 24));
  }

  warningSignUp(signUp: SignUpResponse): boolean {
    return this.getDaysAfterUpdate(signUp.updatedAt) > 6 && this.getDaysAfterUpdate(signUp.updatedAt) < 31;
  }

  errorSignUp(signUp: SignUpResponse): boolean {
    return this.getDaysAfterUpdate(signUp.updatedAt) > 30;
  }

  notFinished(signUp: SignUpResponse): boolean {
    const status: string = signUp.status.toLowerCase();

    return status !== SignUpStatus.CONNECTED &&
      status !== SignUpStatus.DELEGATED &&
      status !== SignUpStatus.REJECTED;
  }

  unprocessedCompanySignUp(signUp: SignUpResponse): boolean {
    return signUp.companyId && signUp.status.toLowerCase() === SignUpStatus.UNPROCESSED;
  }

  activeSignUpWithCompany(signUp: SignUpResponse) {
    return signUp.companyId &&
      (
        signUp.status.toLowerCase() === SignUpStatus.WAIT_ABONENT_CONFIRMATION ||
        signUp.status.toLowerCase() === SignUpStatus.UNPROCESSED ||
        signUp.status.toLowerCase() === SignUpStatus.PROCESSED
      );
  }
}
