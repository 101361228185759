import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Option } from "../../models/select.model";

@Component({
  selector: 'rd-video-select-control',
  templateUrl: './select-control.html',
  styleUrls: ['./select-control.scss']
})
export class RdVideoSelectControlComponent<T> {
  @Input() public set selectValueDefault(value: Option | null) {
    if (!value) return;
    this.selectValueLabel$.next(value.label);
  }
  @Input() isStaticLabel: boolean = false;
  @Input() name: string;
  @Input() options: Option<T>[];
  @Input() icon: string;
  @Input() emptyOptionTemplate: TemplateRef<unknown>;

  selectValueLabel$ = new BehaviorSubject<string>('');
  isHidden = true;

  @ContentChild(TemplateRef) template: TemplateRef<unknown>;

  @Output() selectChange = new EventEmitter<Option>();

  onSelectChange(value: Option): void {
    this.selectValueLabel$.next(value.label);
    this.isHidden = !this.isHidden;
    this.selectChange.emit(value);
  }

  onClick = (): void => {
    this.isHidden = !this.isHidden;
  }
}
