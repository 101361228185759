import {
  Directive,
  ElementRef,
  Input,
  Renderer2,
  OnInit,
  AfterViewInit,
} from '@angular/core';
import { AnimationBuilder, AnimationPlayer, style, animate, AnimationMetadata } from '@angular/animations';
import { BehaviorSubject, of, timer } from 'rxjs';
import { distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { DecorateUntilDestroy, takeUntilDestroyed } from '@app/shared/rxjs/operator/take-until-destroyed';

@DecorateUntilDestroy()
@Directive({
  selector: '[appHoverAnimation]',
})
export class HoverAnimationDirective {
  @Input() set isHovered(value: boolean) {
    this.isHoveredSubject.next(value);
  }

  @Input() hoverDelayIn: number = 600;
  @Input() hoverDelayOut: number = 200;

  private isHoveredSubject = new BehaviorSubject<boolean>(false);
  private isHovered$ = this.isHoveredSubject.asObservable()
    .pipe(
      switchMap((isHovered, index) => {
        if (index === 0) {
          return of(isHovered)
        }

        return isHovered
          ? timer(this.hoverDelayIn).pipe(map(() => isHovered),)
          : timer(this.hoverDelayOut).pipe(map(() => isHovered),)
      }),
      distinctUntilChanged(),
    )

  private player!: AnimationPlayer;

  constructor(
    private el: ElementRef,
    private builder: AnimationBuilder,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    if(!this.isHoveredSubject.value){
      this.renderer.setStyle(this.el.nativeElement, 'opacity', '0');
    }

    this.isHovered$
      .pipe(takeUntilDestroyed(this))
      .subscribe((isHovered) => {
        const metadata = this.animation(isHovered);
        const factory = this.builder.build(metadata);

        if (this.player) {
          this.player.destroy();
        }

        this.player = factory.create(this.el.nativeElement);
        this.player.play();
      });
  }

  private getCurrentStyles(): { transform: string; opacity: string } {
    const computedStyle = getComputedStyle(this.el.nativeElement);
    return {
      transform: computedStyle.transform === 'none' ? 'translateY(0)' : computedStyle.transform,
      opacity: computedStyle.opacity || '1',
    };
  }

  private animation(isHovered: boolean) {
    const { transform, opacity } = this.getCurrentStyles();
    return [
      style({
        transform,
        opacity
      }),
      animate(
        '300ms ease-in-out',
        style({
          transform: isHovered ? 'translateY(0)' : 'translateY(100%)',
          opacity: isHovered ? 1 : 0,
        })
      ),
    ];
  }
}
