import { AfterViewInit, Component, ElementRef, Input, SimpleChanges, ViewChild } from '@angular/core';
import { TZ_OFFSET_IN_MILLISECONDS, VideoPlayerFragmentShortDate } from '@app/shared/components/video-player';
import { DecorateUntilDestroy, takeUntilDestroyed } from '@app/shared/rxjs/operator/take-until-destroyed';
import { TimelineData } from '../../models/timeline.model';
import { MediaPeriodService } from '../../service/media-period.service';
import { StoreService } from '../../service/video-store/store.service';

export enum VideoPlayerGapsColorTypes {
  EVENTS = '#F2CB00',
  GAPS = '#757575',
}
@DecorateUntilDestroy()
@Component({
  selector: 'rd-video-timeline-canvas',
  templateUrl: './timeline-canvas.html',
  styleUrls: ['./timeline-canvas.scss']
})
export class RdVideoTimelineCanvasComponent implements AfterViewInit {
  @ViewChild('canvas') canvasElementRef: ElementRef<HTMLCanvasElement>;

  private readonly CANVAS_WIDTH_MULTIPLIER = 200;

  constructor(private storeService: StoreService, private mediaPeriodService: MediaPeriodService) { }

  ngAfterViewInit(): void {
    this.canvasElementRef.nativeElement.width = 100 * this.CANVAS_WIDTH_MULTIPLIER;
    this.canvasElementRef.nativeElement.height = 15;

    const context = this.canvasElementRef.nativeElement.getContext('2d');
    context.lineWidth = 1;
    context.lineCap = 'butt';
    context.save();

    this.storeService.selectTimelineData$()
      .pipe(takeUntilDestroyed(this))
      .subscribe(timelineData => {
        this.clear();

        this.prepareAndDrawGaps(timelineData);
        this.prepareAndDrawEvents(timelineData);
      })
  }

  prepareAndDrawGaps(timelineData: TimelineData) {
    if (!timelineData.mediaPeriodItems?.length) {
      return;
    }

    // If video starts not from left bound
    const startTimestampWithOffset = timelineData.mediaPeriodItems[0].startTimestamp - TZ_OFFSET_IN_MILLISECONDS;
    const { startTime, endTime } = this.mediaPeriodService.getRangeDateTimeline(timelineData.date, timelineData.mediaPeriodItems[0].startTimestamp);

    const boundsDiff = endTime.getTime() - startTime.getTime();

    if (startTimestampWithOffset > startTime.getTime()) {
      this.drawGap(
        0,
        (startTimestampWithOffset - startTime.getTime()) * 100 / boundsDiff,
        VideoPlayerGapsColorTypes.GAPS
      );
    }

    for (let i = 0; i < timelineData.mediaPeriodItems.length - 1; ++i) {
      if (timelineData.mediaPeriodItems[i + 1].startTimestamp - timelineData.mediaPeriodItems[i].endTimestamp < 10000) {
        continue;
      }

      const leftBoundTimestampWithOffset: number =
        Math.abs(timelineData.mediaPeriodItems[i].endTimestamp - TZ_OFFSET_IN_MILLISECONDS - startTime.getTime());

      this.drawGap(
        leftBoundTimestampWithOffset * 100 / boundsDiff,
        Math.abs(timelineData.mediaPeriodItems[i + 1].startTimestamp - timelineData.mediaPeriodItems[i].endTimestamp) * 100 / boundsDiff,
        VideoPlayerGapsColorTypes.GAPS
      );
    }

    // If video ended not at right bound
    const endTimestampWithOffset = timelineData.mediaPeriodItems[timelineData.mediaPeriodItems.length - 1].endTimestamp - TZ_OFFSET_IN_MILLISECONDS;

    if (endTimestampWithOffset < endTime.getTime()) {
      this.drawGap(
        Math.abs(endTimestampWithOffset - startTime.getTime()) * 100 / boundsDiff,
        100 - Math.abs(endTimestampWithOffset - startTime.getTime()) * 100 / boundsDiff,
        VideoPlayerGapsColorTypes.GAPS
      );
    }
  }

  prepareAndDrawEvents(timelineData: TimelineData) {
    if (!timelineData.eventTimestamps?.length) {
      return;
    }
    const { startTime, endTime } = this.mediaPeriodService.getRangeDateTimeline(timelineData.date, timelineData.mediaPeriodItems[0].startTimestamp);
    const boundsDiff = endTime.getTime() - startTime.getTime();

    for (let i = 0; i < timelineData.eventTimestamps.length; ++i) {
      this.drawGap(
        (timelineData.eventTimestamps[i].startTimestamp - TZ_OFFSET_IN_MILLISECONDS - startTime.getTime()) * 100 / boundsDiff,
        (timelineData.eventTimestamps[i].endTimestamp - timelineData.eventTimestamps[i].startTimestamp) * 100 / boundsDiff,
        VideoPlayerGapsColorTypes.EVENTS
      );
    }
  }

  clear() {
    this.drawGap(0, 100, '#ffffff');
  }

  private drawGap(left: number, width: number, color: string) {
    const context = this.canvasElementRef.nativeElement.getContext('2d');

    context.beginPath();

    context.fillStyle = color;
    context.strokeStyle = color;

    context.moveTo(left * this.CANVAS_WIDTH_MULTIPLIER, 0);
    context.lineTo(left * this.CANVAS_WIDTH_MULTIPLIER, 15);
    context.lineTo((left + width) * this.CANVAS_WIDTH_MULTIPLIER, 15);
    context.lineTo((left + width) * this.CANVAS_WIDTH_MULTIPLIER, 0);
    context.lineTo(left * this.CANVAS_WIDTH_MULTIPLIER, 0);

    context.fill();
    context.stroke();
  }
}
