<ng-container [ngSwitch]="mode$ | async">
  <ng-container *ngSwitchCase="VideoPlayerMode.LIVE_WEBRTC" [ngTemplateOutlet]="videoBlock"></ng-container>
  <ng-container *ngSwitchCase="VideoPlayerMode.LIVE_HLS" [ngTemplateOutlet]="videoBlock"></ng-container>
  <ng-container *ngSwitchCase="VideoPlayerMode.ARCHIVE" [ngTemplateOutlet]="videoBlock"></ng-container>
</ng-container>

<ng-template #videoBlock>
  <div class="video-container"
    (mouseenter)="onMouseEnter()"
    (mouseleave)="onMouseLeave()"
  >
    <div class="video-wrapper">
      <rd-video [streamId]="streamId">
        <video #video class="video-block"></video>
      </rd-video>
    </div>

    <div class="overlay-play">
      <rd-video-play-control [isHideInPauseState]="true" [isRoundButton]="true" [isActive]="true"></rd-video-play-control>
    </div>

    <div class="overlay-container">
      <div class="overlay-content">
        <div class="controls-container" [isHovered]="isHoveredControl$ | async" appHoverAnimation *ngIf="isShowControl">
          <div class="controls-container__flex-central" >
            <ng-container *ngIf="(mode$ | async) === VideoPlayerMode.ARCHIVE">
              <rd-video-time-manage-control [step]="timeStepManage" [type]="'prev'"></rd-video-time-manage-control>
              <rd-video-time-manage-control [step]="timeStepManage" [type]="'next'"></rd-video-time-manage-control>

              <rd-video-select-control 
                [icon]="'assets/icons/ic-call-answer.svg'" 
                [options]="eventsOptions$ | async"
                [emptyOptionTemplate]="emptyOptionEvent"
                [name]="'shared.video_player.plyr.template.button.events' | translate"
                (selectChange)="handleSelectionEvents($event)"
              >
                <ng-template let-onClick="onClick" let-selectValueLabel="selectValueLabel">
                  <ng-container 
                    *ngTemplateOutlet="
                      changeEventBtn;
                      context: { onClick: this.onClick , selectValueLabel: selectValueLabel}
                    "
                  ></ng-container>
                </ng-template>
              </rd-video-select-control >
              <rd-video-select-control
                [selectValueDefault]="currentPlaylistOption$ | async"
                [options]="dateArchiveOptions$ | async"
                (selectChange)="handleSelectionPlaylist($event)"
              >
                <ng-template let-onClick="onClick" let-selectValueLabel="selectValueLabel">
                  <ng-container 
                    *ngTemplateOutlet="
                      changePlaylist;
                      context: { onClick: this.onClick , selectValueLabel: selectValueLabel}
                    "
                  ></ng-container>
                </ng-template>
              </rd-video-select-control>
  
              <rd-video-control (clickEvent)="openVideoShotPopup()">
                <ng-template let-onClick="onClick">
                  <ng-container 
                    *ngTemplateOutlet="
                      downloadBtn;
                      context: { onClick: this.onClick }
                    "
                  ></ng-container>
                </ng-template>
              </rd-video-control>
            </ng-container>
            <rd-video-control
              *ngIf="isShowOpenDoor"
              (clickEvent)="openDoor()"
            >
              <ng-template let-onClick="onClick">
                <ng-container 
                  *ngTemplateOutlet="
                    openDoorBtn;
                    context: { onClick: this.onClick }
                  "
                ></ng-container>
              </ng-template>
            </rd-video-control>
          </div>
          <div class="controls-container__flex-end">
            <rd-video-control (clickEvent)="changeMode()">
              <ng-template 
                let-onClick="onClick"
              >
                <ng-container 
                  *ngTemplateOutlet="
                    changeModeBtn;
                    context: { onClick: this.onClick }
                  "
                ></ng-container>
              </ng-template>
            </rd-video-control>
          </div>
        </div>
        <div class="timeline-container" [isHovered]="isHoveredTimeline$ | async" appHoverAnimation>
          <rd-video-play-control></rd-video-play-control>
          <ng-container *ngIf="(mode$ | async) === VideoPlayerMode.ARCHIVE">
            <rd-video-timeline class="timeline"></rd-video-timeline>
          </ng-container>

          <div class="other-controls">
            <rd-video-volume-control class="volume-control"></rd-video-volume-control>
            <rd-video-pip-control></rd-video-pip-control>
            <rd-video-full-screen-control></rd-video-full-screen-control>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #emptyOptionEvent>
  <span>{{'shared.video_player.plyr.template.message.no_events' | translate }}</span>
</ng-template>

<ng-template #openDoorBtn
  let-onClick="onClick"
>
  <p-button [label]="'shared.video_player.plyr.template.button.open_door'| translate"
    icon="pi pi-sign-in pi-icon--blue" iconPos="left"
    styleClass="p-button-info p-button-text"
    (onClick)="onClick()"></p-button>
</ng-template>

<ng-template #downloadBtn
  let-onClick="onClick"
>
  <p-button *ngIf="!errorLoadingPlaylist || !errorLoadingVideo"
    [label]="'shared.video_player.plyr.template.button.get_video_shot' | translate"
    styleClass="p-button-info p-button-text"
    (onClick)="onClick()"></p-button>
</ng-template>

<ng-template #changeModeBtn 
  let-onClick="onClick"
>
  <p-button [label]="getModeButtonName()"
    styleClass="p-button-info p-button-text" 
    [icon]="getModeButtonIcon()"
    [iconPos]="'left'"
    (onClick)="onClick()"
  ></p-button>
</ng-template>


<ng-template #changeEventBtn 
  let-onClick="onClick"
  let-selectValueLabel="selectValueLabel"
>
  <p-button 
    styleClass="p-button-info p-button-text"
    [label]="'abonent.page.info.header.events' | translate"
    (onClick)="onClick()">
  </p-button>
</ng-template>

<ng-template #changePlaylist 
  let-onClick="onClick"
  let-selectValueLabel="selectValueLabel"
>
  <p-button 
    [label]="selectValueLabel"
    styleClass="p-button-info p-button-text"
    (onClick)="onClick()">
  </p-button>
</ng-template>
