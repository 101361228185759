import {ChangeDetectionStrategy, Component, ViewEncapsulation} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ButtonModule} from 'primeng/button';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {DynamicDialogRef, DynamicDialogConfig} from 'primeng/dynamicdialog';
import {ResolutionBreakpoint, ResolutionService} from '@app/shared/services';

export namespace ApartmentVideoRemoveService {
  export enum ConfirmType {
    RemovingAVService = 'RemovingAVService',
    BlockingAVService = 'BlockingAVService',
    UnblockingAVService = 'UnblockingAVService',
    RemovingCamera = 'RemovingCamera'
  }

  export interface Output {
    confirm: boolean;
  }
}

@Component({
  selector: 'app-apartment-video-remove-service',
  standalone: true,
  imports: [CommonModule, ButtonModule, TranslateModule],
  templateUrl: './apartment-video-remove-service.component.html',
  styleUrls: ['./apartment-video-remove-service.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ApartmentVideoRemoveServiceComponent {
  public readonly ConfirmType = ApartmentVideoRemoveService.ConfirmType;

  public get name(): string {
    return this.dynamicDialogConfig.data?.name;
  }

  public get confirmType(): ApartmentVideoRemoveService.ConfirmType {
    return this.dynamicDialogConfig.data?.confirmType;
  }

  public get confirmButtonLabel(): string {
    switch (this.confirmType) {
      case ApartmentVideoRemoveService.ConfirmType.RemovingAVService: {
        return this.translateService.instant('apartments_video_surveillance.popup.removing_service');
      }
      case ApartmentVideoRemoveService.ConfirmType.RemovingCamera: {
        return this.translateService.instant('apartments_video_surveillance.popup.delete_camera');
      }
      case ApartmentVideoRemoveService.ConfirmType.BlockingAVService: {
        return this.translateService.instant('apartments_video_surveillance.popup.block');
      }
      case ApartmentVideoRemoveService.ConfirmType.UnblockingAVService: {
        return this.translateService.instant('apartments_video_surveillance.popup.unblock');
      }
    }
  }

  public get confirmButtonType(): string {
    switch (this.confirmType) {
      case ApartmentVideoRemoveService.ConfirmType.RemovingAVService:
      case ApartmentVideoRemoveService.ConfirmType.RemovingCamera:
      case ApartmentVideoRemoveService.ConfirmType.BlockingAVService: {
        return 'p-button-danger';
      }
      case ApartmentVideoRemoveService.ConfirmType.UnblockingAVService: {
        return 'p-button-success';
      }
    }
  }

  constructor(
    private dynamicDialogRef: DynamicDialogRef,
    private dynamicDialogConfig: DynamicDialogConfig,
    private translateService: TranslateService,
    private resolutionService: ResolutionService,
  ) {
    this.dynamicDialogConfig.styleClass = 'apartment-video-remove-service__modal';
    if (this.resolutionService.getBreakpointState(ResolutionBreakpoint.SM_W_DOWN)) {
      this.dynamicDialogConfig.height = '100%';
      this.dynamicDialogConfig.width = '100%';
      this.dynamicDialogConfig.styleClass = `${this.dynamicDialogConfig.styleClass} apartment-video-remove-service__modal--full-screen`;
    }
  }

  public onConfirmAction(): void {
    this.dynamicDialogRef.close({confirm: true});
  }
}
