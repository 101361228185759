<div class="popup intercom-key-delete-content">
  <app-info-block>
    <ng-container info-block-text>
      <span
        [innerHTML]="'shared.intercom.key.delete.container.text' | translate: {
          name: location ? location.name : intercomKeyType ? (intercomKeyRecords[intercomKeyType].name | translate ) : ''
        }"
      ></span>
    </ng-container>
  </app-info-block>

  <div class="popup-actions">
    <button
      color="error"
      mat-raised-button
      (click)="onDelete()"
      [disabled]="loading"
      class="popup-actions__button"
    >
      {{ 'shared.intercom.key.delete.container.button.submit' | translate }}
    </button>
  </div>
</div>
