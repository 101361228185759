import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {SnackbarService} from '@app/shared/components';
import {ServiceCreateResponse, ServiceResponse, VideoSurveillanceService} from '@app/shared/entities/rd';
import {parseError} from '@app/shared/helpers';
import {Address, ServicesTypes} from '@app/shared/models';
import {DialogWrapperService} from '@app/shared/ui';
import {
  GetServiceConnectionsInit,
  GetServiceSuccess,
  ServiceFacade,
  ServicesActionTypes
} from '@app/views/services/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {catchError, map, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {VideoSurveillancePageService} from './../services';
import {
  CreateVideoSurveillance,
  CreateVideoSurveillanceFailure,
  CreateVideoSurveillanceSuccess,
  DeleteVideoSurveillance,
  DeleteVideoSurveillanceFailure,
  DeleteVideoSurveillanceSuccess,
  GetVideoSurveillanceList,
  GetVideoSurveillanceListFailure,
  GetVideoSurveillanceListSuccess,
  VideoSurveillanceListActionTypes
} from './video-surveillance-list.actions';
import {VideoSurveillanceListFacade} from './video-surveillance-list.facade';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class VideoSurveillancePageEffects {
  constructor(
    private router: Router,
    private actions$: Actions,
    private dialog: MatDialog,
    private snackbar: SnackbarService,
    private serviceFacade: ServiceFacade,
    private dialogWrapperService: DialogWrapperService,
    private videoSurveillanceService: VideoSurveillanceService,
    private videoSurveillanceListFacade: VideoSurveillanceListFacade,
    private videoSurveillancePageService: VideoSurveillancePageService,
    private translate: TranslateService
  ) {
  }

  GetVideoSurveillanceList$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetVideoSurveillanceList>(VideoSurveillanceListActionTypes.GetVideoSurveillanceList),
      switchMap(action =>
        this.videoSurveillanceService.getPage(action.page, action.size, action.search, action.sort)
          .pipe(
            map(serviceInfoResponsePage =>
              new GetVideoSurveillanceListSuccess(
                this.videoSurveillancePageService.preparePageResponse(serviceInfoResponsePage.content),
                serviceInfoResponsePage
              )
            ),
            catchError(error => of(new GetVideoSurveillanceListFailure(error)))
          )
      )
    )
  );

  CreateVideoSurveillance$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateVideoSurveillance>(VideoSurveillanceListActionTypes.CreateVideoSurveillance),
      switchMap((action: CreateVideoSurveillance) => {
          this.dialogWrapperService.pendingState = true;

          return this.videoSurveillanceService.create(action.request)
            .pipe(
              map((response: ServiceCreateResponse) => {
                this.dialogWrapperService.pendingState = false;
                this.snackbar.showMessage(
                  this.translate.instant('services.video_surveillance.flats.list.message.create_video_surveillance.success', {
                    name: response.customName ?? response.name
                  }),
                  'success'
                );

                this.router
                  .navigate([`services/video-surveillance`, response.id], {
                    queryParams: {
                      'query': action.options.filterValue ?? null,
                      'page': action.options.pageIndex,
                      'address': response.customName ?? response.name,
                    }
                  })
                  .then(() => this.dialog.closeAll());

                return new CreateVideoSurveillanceSuccess();
              }),
              catchError((error: HttpErrorResponse) => {
                this.dialogWrapperService.pendingState = false;
                this.snackbar.showMessage(
                  this.translate.instant('services.video_surveillance.flats.list.message.create_video_surveillance.failed', {
                    text: parseError(error)
                  })
                );
                return of(new CreateVideoSurveillanceFailure());
              })
            );
        }
      )
    )
  );

  DeleteVideoSurveillance$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteVideoSurveillance>(VideoSurveillanceListActionTypes.DeleteVideoSurveillance),
      switchMap((action: DeleteVideoSurveillance) => {
        this.dialogWrapperService.pendingState = true;

        return this.videoSurveillanceService.deleteService(action.serviceId)
          .pipe(
            map(() => {
              this.snackbar.showMessage(
                this.translate.instant('services.video_surveillance.flats.list.message.delete_video_surveillance.success'),
                'success'
              );
              this.dialogWrapperService.pendingState = false;
              this.dialog.closeAll();
              this.videoSurveillanceListFacade.getPage(action.options.pageIndex, action.options.pageSize, action.options.filterValue);
              return new DeleteVideoSurveillanceSuccess();
            }),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('services.video_surveillance.flats.list.message.delete_video_surveillance.failed', {
                  text: parseError(error)
                })
              );
              this.dialogWrapperService.pendingState = false;
              return of(new DeleteVideoSurveillanceFailure());
            })
          );
      })
    )
  );

  // General services effecs
  GetServiceSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetServiceSuccess>(ServicesActionTypes.GetServiceSuccess),
      withLatestFrom(this.serviceFacade.serviceType$),
      tap(([action, serviceType]: [GetServiceSuccess, ServicesTypes]) => {
        if (serviceType !== ServicesTypes.VIDEO_SURVEILLANCE) {
          return;
        }

        if (action.response.entrances.length > 0) {
          this.serviceFacade.getConnectionsInit();
        } else {
          this.serviceFacade.setFillEmptyFlats(false);
        }
      })
    ), {dispatch: false}
  );

  GetServiceConnectionsInit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<GetServiceConnectionsInit>(ServicesActionTypes.GetServiceConnectionsInit),
      withLatestFrom(
        this.serviceFacade.serviceType$,
        this.serviceFacade.entrances$
      ),
      tap(([action, serviceType, addresses]:
             [GetServiceConnectionsInit, ServicesTypes, Address[]]
      ) => {
        if (serviceType !== ServicesTypes.VIDEO_SURVEILLANCE) {
          return;
        }

        this.serviceFacade.getConnections(
          [],
          addresses.map(address => address.entrance.id)
        );
      })
    );
  }, {dispatch: false});
}
