import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DecorateUntilDestroy, takeUntilDestroyed } from "@app/shared/rxjs/operator/take-until-destroyed";
import { PlayerStateEnum } from "../../models/state.model";
import { map, startWith } from "rxjs/operators";
import { BehaviorSubject } from "rxjs";
import { StoreService } from "../../service/video-store/store.service";

enum PlayOrPauseIconUrlEnum {
  play = 'assets/icons/pause.svg',
  pause = 'assets/icons/play.svg'
}

@DecorateUntilDestroy()
@Component({
  selector: 'rd-video-play-control',
  templateUrl: './play-control.html',
  styleUrls: ['./play-control.scss']
})
export class RdVideoPlayControlComponent {
  @Input() isActive: boolean = false;
  @Input() isRoundButton: boolean = false;
  @Input() isHideInPauseState: boolean = false;

  isHideInPauseState$ = this.storeService.selectPlayerState$()
    .pipe(
      map(value => {
        if (value === PlayerStateEnum.PLAY && this.isHideInPauseState) {
          return false;
        }
        return true;
      })
    )

  iconUlr$ = this.storeService.selectPlayerState$()
    .pipe(
      map(state => {
        switch (state) {
          case PlayerStateEnum.PLAY:
            return PlayOrPauseIconUrlEnum.play
          case PlayerStateEnum.PAUSE:
          case PlayerStateEnum.LOAD:
            return PlayOrPauseIconUrlEnum.pause
        }
      })
    )

  constructor(private storeService: StoreService) { }

  onControlClick(): void {
    const playerState = this.storeService.getPlayerState() === PlayerStateEnum.PLAY ? PlayerStateEnum.PAUSE : PlayerStateEnum.PLAY;
    this.storeService.fireNewPlayerState(playerState)
  }
}
