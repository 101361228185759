import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from "@angular/core";
import { DecorateUntilDestroy, takeUntilDestroyed } from "@app/shared/rxjs/operator/take-until-destroyed";
import { distinctUntilChanged } from "rxjs/operators";
import { StoreService } from "../../service/video-store/store.service";

@DecorateUntilDestroy()
@Component({
  selector: 'rd-video-pip-control',
  templateUrl: './pip-control.html'
})

export class RdVideoPipControlComponent {
   readonly iconUlr: string = 'assets/icons/plyr-pip.svg';

  constructor(private storeService: StoreService) { }

  onControlClick(): void {
    this.storeService.fireRequestPictureInPicture();
  }
}
