import { PermissionsTreeKey, PermissionsTreeKeysType, PermissionsTreeRules } from './models';
import { PermissionsTreeBase } from './permissions-tree-base';

export class PermissionsTreeAdmin extends PermissionsTreeBase {
  constructor() {
    const defaultRoute = '/dashboard';

    const keys: PermissionsTreeKeysType = {
      [PermissionsTreeKey.ABONENTS_SIGN_UPS]: true,
      [PermissionsTreeKey.DASHBOARD]: true
    };

    const routes: PermissionsTreeRules = {
      dashboard: { enabled: true },
      pbx: { enabled: false },
      entrances: {
        enabled: true,
        childrens: {
          id: { enabled: true }
        }
      },
      'personal_surveillance': {
        enabled: false,
        childrens: {
          id: { enabled: false }
        }
      },
      intercom: {
        childrens: {
          adapters: { enabled: true },
          cameras: { enabled: true },
          'new-adapters': { enabled: false },
          batches: { enabled: false },
          shields: {
            enabled: false,
            childrens: {
              shield: { enabled: false },
            }
          },
          models: { enabled: false },
          orders: { enabled: false },
        }
      },
      abonents: {
        childrens: {
          subscribers: {
            enabled: true,
            childrens: {
              subscriber: { enabled: true },
            }
          },
          sign_ups: { enabled: true },
        }
      },
      mailing: {
        childrens: {
          all: { enabled: true },
          new: { enabled: true },
          id: { enabled: true },
        }
      },
      services: {
        enabled: true,
        childrens: {
          'video-surveillance': {
            enabled: true,
            childrens: {
              id: { enabled: true }
            }
          },
          'video-manager': {
            enabled: false,
            childrens: {
              add: { enabled: false },
              edit: { enabled: false },
              show: { enabled: false }
            }
          },
          gates: {
            enabled: true,
            childrens: {
              id: { enabled: true }
            }
          },
          'software-intercoms': {
            enabled: true,
            childrens: {
              id: { enabled: true }
            }
          }
        }
      },
      payments: { enabled: true },
      login: { enabled: true },
      support: { enabled: true }
    };

    super(defaultRoute, keys, routes);
  }
}
